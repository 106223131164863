import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";

import { environment } from "@env/environment";
import { Logger, UntilDestroy, untilDestroyed } from "@shared";
import { AuthenticationService } from "./authentication.service";
import { MustMatch } from "@app/@shared/helpers/must-match.validator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

const log = new Logger("Reset Password");

@UntilDestroy()
@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  passwordForm!: FormGroup;
  isLoading = false;
  hide: boolean = true;

  uid: string | null;
  token: string | null;
  isReset?: string | null = "";

  isAdmin: boolean = false;
  resetSuccessful: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.uid = this.route.snapshot.queryParamMap.get("uid");
    this.token = this.route.snapshot.queryParamMap.get("token");
    if (this.route.snapshot.queryParamMap.get("reset")) {
      this.isReset = JSON.parse(
        this.route.snapshot.queryParamMap.get("reset")!.toLowerCase()
      );
    }
    if (this.route.snapshot.queryParamMap.get("is_admin")) {
      this.isAdmin = JSON.parse(
        this.route.snapshot.queryParamMap.get("is_admin")!.toLowerCase()
      );
    }

    this.createForm();
  }

  ngOnInit() {
    sessionStorage.clear();
  }

  resetPassword() {
    this.isLoading = true;

    let data = {
      password: this.passwordForm.value.newPassword,
      password2: this.passwordForm.value.repeatPassword,
      uidb64: this.uid,
      token: this.token,
    };

    const reset$ = this.authenticationService.resetPassword(data);

    reset$
      .pipe(
        finalize(() => {
          this.passwordForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (response) => {
          this._snackBar.open(
            this.translateService.instant("lbl-password-updated"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );

          if (this.isAdmin) {
            this.router.navigate(["/signin"], { replaceUrl: true });
          } else {
            this.resetSuccessful = true;
          }
        },
        (error) => {
          this._snackBar.open(
            this.translateService.instant("lbl-something-wrong"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["error-snackbar"],
            }
          );

          this.error = error;
        }
      );
  }
  private createForm() {
    this.passwordForm = this.formBuilder.group(
      {
        newPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
            ),
          ],
        ],
        repeatPassword: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
            ),
          ],
        ],
      },
      {
        validator: MustMatch("newPassword", "repeatPassword"),
      }
    );
  }
}
