import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { SharedModule } from "@shared";
import { MaterialModule } from "@app/material.module";
import { I18nModule } from "@app/i18n";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./login.component";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    I18nModule,
    AuthRoutingModule,
  ],
  declarations: [LoginComponent, ForgotPasswordComponent],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5500 } },
  ],
})
export class AuthModule {}
