<div class="dialog-container" fxLayout="column">
  <h1 mat-dialog-title translate>lbl-provider-deletion</h1>
  <div mat-dialog-content fxLayout="column" fxFlex="50%">
    <p translate>lbl-are-you-sure</p>
  </div>
  <div align="center">
    <div class="auth-button-border dialog" fxLayoutAlign="center center">
      <button
        mat-button
        color="primary"
        (click)="delete()"
        id="auth-button"
        type="button"
      >
        <!-- <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader> -->
        <span translate>lbl-yes</span>
      </button>
    </div>
    <div class="auth-button-border dialog" fxLayoutAlign="center center">
      <button mat-button mat-dialog-close color="primary" id="cancel-button">
        <span translate>lbl-cancel</span>
      </button>
    </div>
  </div>
</div>
