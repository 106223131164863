<div fxFlex class="shell-container">
  <app-header [sidenav]="appDrawer"></app-header>
  <mat-sidenav-container fxFill fxLayout="row">
    <mat-sidenav
      #appDrawer
      fxFlex="15"
      [mode]="mode"
      opened="true"
      disableClose="true"
      ngClass.gt-sm="has-border"
    >
      <mat-nav-list>
        <app-menu-list-item
          *ngFor="let item of navItems"
          [item]="item"
          [items]="navItems"
          (expand)="onExpand($event)"
        ></app-menu-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content #sidenavContent fxLayout="row">
      <div fxFlex="96" class="router-outlet-container">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <ngx-ui-loader
          overlayColor="#f6f6f6"
          pbColor="#ff6900"
          fgsColor="#ff6900"
          [loaderId]="'shell-loader'"
        ></ngx-ui-loader>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<app-footer></app-footer>
