<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="end start"
  #administratorList
>
  <div class="container-wrappers" fxFlex="84">
    <div class="container main-menu-element-container">
      <div
        class="title-wrapper"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <h2 *ngIf="!data.search" id="title" translate>
          List Of Administrators
        </h2>
        <h2 *ngIf="data.search" id="title" translate>
          {{ "lbl-search-result" | translate }} '{{ data.search }}'
        </h2>

        <form class="administrator-form" novalidate>
          <mat-form-field>
            <mat-label translate>lbl-sort-by</mat-label>
            <mat-select
              matSelect
              [(value)]="data.sortBy"
              (selectionChange)="sortTableBy($event)"
            >
              <mat-option value="">{{
                "lbl-date-added" | translate
              }}</mat-option>
              <mat-option value="first_name">{{
                "lbl-name" | translate
              }}</mat-option>
              <mat-option value="username">{{
                "lbl-username" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label translate>lbl-show</mat-label>
            <mat-select
              matSelect
              [(value)]="data.limit"
              (selectionChange)="showTableBy($event)"
            >
              <mat-option value="10">10</mat-option>
              <mat-option value="20">20</mat-option>
              <mat-option value="50">50</mat-option>
              <mat-option value="100">100</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <mat-card>
        <mat-table
          [dataSource]="
            providersAdmin
              | paginate
                : {
                    itemsPerPage: data.limit,
                    currentPage: data.page,
                    totalItems: totalItems
                  }
          "
          matSort
        >
          <ng-container matColumnDef="icon">
            <mat-header-cell
              fxFlex="90px"
              *matHeaderCellDef
              translate
            ></mat-header-cell>
            <mat-cell fxFlex="90px" *matCellDef="let element">
              <ngx-avatar
                name="{{ element.name }}"
                initialsSize="4"
              ></ngx-avatar>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell fxFlex="36" *matHeaderCellDef translate
              >lbl-name</mat-header-cell
            >
            <mat-cell fxFlex="36" *matCellDef="let element"
              ><div class="cell-name-wrapper">
                <div class="cell-name-elements">
                  <span
                    class="name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.name }}</span
                  >
                  <span
                    class="sub-name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.email }}</span
                  >
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="username">
            <mat-header-cell fxFlex="17" *matHeaderCellDef translate>{{
              "lbl-username" | translate
            }}</mat-header-cell>
            <mat-cell fxFlex="17" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                >{{ element.username }}</span
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="address">
            <mat-header-cell fxFlex="32" *matHeaderCellDef translate
              >lbl-address</mat-header-cell
            >
            <mat-cell fxFlex="32" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{ element.address }}
              </span></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="tel_number">
            <mat-header-cell fxFlex="15" *matHeaderCellDef translate
              >lbl-phone-number</mat-header-cell
            >
            <mat-cell fxFlex="15" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                >{{ element.tel_number }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsAdmin"
          ></mat-header-row>
          <mat-row
            (click)="goToIndividualScreen(row)"
            *matRowDef="let row; columns: displayedColumnsAdmin"
          ></mat-row>
        </mat-table>
      </mat-card>
      <div class="pagination-wrapper" fxLayoutAlign="center center">
        <pagination-controls
          previousLabel=""
          nextLabel=""
          (pageChange)="onPageChange($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasAddAdministratorRedirect]="true"
    fxFlex="30"
    fxFlex.gt-sm="14"
  ></app-sidebar-buttons>
  <ngx-ui-loader
  overlayColor="#f6f6f6"
  pbColor="#ff6900"
  fgsColor="#ff6900"
  [loaderId]="'homepage-loader'"
></ngx-ui-loader>
</div>
