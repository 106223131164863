import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { ModelsService } from "@app/@shared/services/models.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable } from "rxjs/internal/Observable";
import { HomeService } from "../home.service";
import {saveAs} from "file-saver";

@Component({
  selector: "app-list-users",
  templateUrl: "./list-users.component.html",
  styleUrls: ["./list-users.component.scss"],
})
export class ListUsersComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = ["One", "Two", "Three"];
  filteredOptions!: Observable<string[]>;
  isLoading: boolean = false;

  sortBy: string = "date_added";
  totalItems: number = 0;

  data = {
    offset: 0,
    limit: "10",
    page: 1,
    search: "",
    sortBy: "",
  };

  users = [{}];
  providersUsers = [{}];

  displayedColumnsUser: string[] = [
    "icon",
    "name",
    "username",
    "address",
    "tel_number",
  ];
  @ViewChild(MatSort)
  sort!: MatSort;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private modelsService: ModelsService
  ) {
    this.getAllUsers();

    this.modelsService.searchData.subscribe((value) => {
      this.data.search = value;
      this.getAllUsers();
    });
  }

  ngOnInit(): void {}

  sortTableBy(event: any) {
    let _sortBy = event.value;
    this.data.sortBy = _sortBy;
    this.getAllUsers();
  }

  getAllUsers() {
    this.ngxLoader.startLoader("homepage-loader");

    this.homeService.getAllUsers(this.data).subscribe(
      (response) => {
        this.ngxLoader.stopLoader("homepage-loader");

        this.providersUsers = response;
        this.totalItems = response.count;
        this.providersUsers = response.results;

        this.isLoading = false;
      },
      (error) => {
        this.ngxLoader.stopLoader("homepage-loader");
      }
    );
  }

  exportUsers() {
    this.homeService.exportAllUsers(this.data).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: "text/csv;charset=utf-8"
      });

      saveAs(data, "platform-users.csv");
    });
  }

  showTableBy(event: any) {
    let _showBy = event.value;
    this.data.limit = _showBy;
    if (this.totalItems < +this.data.limit * this.data.page) {
      this.data.page = Math.ceil(this.totalItems / +this.data.limit);
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllUsers();
  }

  onPageChange(event: any) {
    this.providersUsers = [];
    this.data.page = event;
    if (this.data.page == 0) {
      this.data.offset = 0;
    } else {
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllUsers();
  }

  goToIndividualScreen(entity: any) {
    this.router.navigate(["/platform-user", entity.id]);
  }

  mouseEnter(event: any) {
    let element = event.srcElement;
    element.setAttribute("title", element.innerHTML);
  }

  mouseLeave(event: any) {}
}
