<div
  *ngIf="currAdministrator"
  class="buttons"
  fxFlex="30"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <app-language-selector [icon]="true"></app-language-selector>
  <div
    class="admin-data"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="goToUserPage(currAdministrator && currAdministrator.id)"
  >
    <div class="admin-icon-wrapper">
      <img class="admin-icon" src="../../../assets/img_profile.svg" />
    </div>
    <div class="admin-info">
      <p class="admin-name">{{'lbl-hi' | translate}}{{ currAdministrator?.first_name }}</p>
      <span class="admin-email">{{ currAdministrator?.email }}</span>
    </div>
  </div>
  <div class="logout-wrapper">
    <button class="logout-button" mat-icon-button (click)="logout()" translate>
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</div>
