import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Administrator } from "@app/@shared/interfaces/administrator.interface";
import { Logger } from "@app/@shared/logger.service";
import { LoaderService } from "@app/@shared/services/loader.service";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../home.service";

const log = new Logger("AdministratorComponent");

@Component({
  selector: "app-add-new-administrator",
  templateUrl: "./add-new-administrator.component.html",
  styleUrls: ["./add-new-administrator.component.scss"],
})
export class AddNewAdministratorComponent implements OnInit {
  administratorForm!: FormGroup;
  administrator?: Administrator;

  isEdit: boolean = false;
  routeId: string | null = "";

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    if (this.route.snapshot.paramMap.get("id")) {
      this.routeId = this.route.snapshot.paramMap.get("id");
      this.isEdit = true;
    }
    if (this.router.getCurrentNavigation()?.extras.state) {
      this.administrator = this.router.getCurrentNavigation()?.extras
        .state as Administrator;
    } else {
      if (this.routeId) {
        this.getAdministrator(this.routeId);
      }
    }
    this.createForm();
  }

  ngOnInit(): void {}

  /**
   * Create Form Function
   */
  createForm() {
    console.log("administrator", this.administrator);
    this.administratorForm = this.formBuilder.group({
      first_name: [this.administrator?.first_name || "", Validators.required],
      last_name: [this.administrator?.last_name || "", Validators.required],
      address: [this.administrator?.address || "", Validators.required],
      email: [
        this.administrator?.email || "",
        Validators.compose([Validators.required, Validators.email]),
      ],
      tel_number: [this.administrator?.tel_number || "", Validators.required],
    });

    if (this.isEdit && this.administratorForm.get("email")!.value != null) {
      this.administratorForm.get("email")!.disable();
    }
  }

  /**
   * Get Administrator
   */
  getAdministrator(id: string) {
    this.loaderService.shouldRunLoader.next(true);

    this.homeService.getAdminById(+id).subscribe(
      (response) => {
        this.administrator = response;
        this.loaderService.shouldRunLoader.next(false);
        this.createForm();
        log.debug("Current Administrator", this.administrator);
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);

        log.error(error);
      }
    );
  }

  /**
   * Submits form
   */
  addNewAdministrator(): void {
    if (this.administratorForm.invalid) {
      this.administratorForm.markAllAsTouched();
      return;
    }
    let administratorValue = this.administratorForm.value;
    administratorValue.username = this.administratorForm.value.email;
    this.loaderService.shouldRunLoader.next(true);

    if (!this.isEdit) {
      this.homeService.postNewAdmin(this.administratorForm.value).subscribe(
        (res) => {
          this._snackBar.open(
            this.translateService.instant("lbl-administrator-created"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );
          this.loaderService.shouldRunLoader.next(false);

          this.administratorForm.reset();
          return this.router.navigate(["/list-administrators"]);
        },
        (error) => {
          this._snackBar.open(
            this.translateService.instant("lbl-something-wrong"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["error-snackbar"],
            }
          );
          this.loaderService.shouldRunLoader.next(false);
          console.log(error);
        }
      );
    }
    {
      if (this.routeId) {
        this.homeService
          .updateAdministrator(this.routeId, this.administratorForm.value)
          .subscribe(
            (res) => {
              this._snackBar.open(
                this.translateService.instant("lbl-administrator-created"),
                this.translateService.instant("lbl-cancel"),
                {
                  panelClass: ["success-snackbar"],
                }
              );
              this.loaderService.shouldRunLoader.next(false);

              this.administratorForm.reset();
              return this.router.navigate(["/administrator", this.routeId]);
            },
            (error) => {
              this._snackBar.open(
                this.translateService.instant("lbl-something-wrong"),
                this.translateService.instant("lbl-cancel"),
                {
                  panelClass: ["error-snackbar"],
                }
              );
              this.loaderService.shouldRunLoader.next(false);
              console.log(error);
            }
          );
      }
    }
  }

  goBack() {
    if (this.routeId) {
      return this.router.navigate(["/administrator", this.routeId]);
    } else {
      return this.router.navigate(["/list-administrators"]);
    }
  }
}
