import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { LoaderService } from "@app/@shared/services/loader.service";
import { ModelsService } from "@app/@shared/services/models.service";

@Component({
  selector: "app-sidebar-buttons",
  templateUrl: "./sidebar-buttons.component.html",
  styleUrls: ["./sidebar-buttons.component.scss"],
})
export class SidebarButtonsComponent implements OnInit {
  @Input() hasAddAdministrator?: boolean = false;
  @Input() hasEditAboutUs?: boolean = false;
  @Input() hasEditTerms?: boolean = false;
  @Input() hasEditInformation?: boolean = false;
  @Input() hasResetPassword?: boolean = false;
  @Input() hasSaveChanges?: boolean = false;
  @Input() hasEditPrivacyPolicy: boolean = false;
  @Input() hasSaveProvider: boolean = false;
  @Input() hasCancel?: boolean = false;
  @Input() hasAddAdministratorRedirect?: boolean = false;
  @Input() hasAddProviderRedirect?: boolean = false;
  @Input() hasEnableProfile?: boolean = false;
  @Input() hasSuspendProfile?: boolean = false;
  @Input() hasDeleteProfile?: boolean = false;

  @Input() edit?: boolean = false;
  @Input() view?: boolean = true;

  @Output() addAdministrator = new EventEmitter<any>();
  @Output() editAbout = new EventEmitter<any>();
  @Output() editTermsOfUse = new EventEmitter<any>();
  @Output() editPrivacyPolicy = new EventEmitter<any>();
  @Output() saveAbout = new EventEmitter<any>();
  @Output() editInformation = new EventEmitter<any>();
  @Output() resetPassword = new EventEmitter<any>();
  @Output() switchEditViewMode = new EventEmitter<any>();
  @Output() suspendProfile = new EventEmitter<any>();
  @Output() enableProfile = new EventEmitter<any>();
  @Output() deleteProfile = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();

  isCurrAdministratorSuperUser: boolean = false;

  constructor(private router: Router, private modelService: ModelsService) {}

  ngOnInit(): void {
    this.modelService.currLoggedInAdminSuperAdmin.subscribe((value) => {
      if (value != undefined) {
        this.isCurrAdministratorSuperUser = value;
      }
    });
  }

  addNewAdministrator() {
    this.addAdministrator.emit();
  }

  editAboutClicked() {
    this.editAbout.emit();
  }

  editTermsOfUseClicked() {
    this.editTermsOfUse.emit();
  }

  editPrivacyPolicyClicked() {
    this.editPrivacyPolicy.emit();
  }

  saveAboutClicked() {
    this.saveAbout.emit();
  }

  editInformationClicked() {
    this.editInformation.emit();
  }

  resetPasswordClicked() {
    this.resetPassword.emit();
  }

  suspendProfileClicked() {
    this.suspendProfile.emit();
  }

  enableProfileClicked() {
    this.enableProfile.emit();
  }

  deleteProfileClicked() {
    this.deleteProfile.emit();
  }
  
  goToIndex() {
    if (
      (this.hasEditAboutUs || this.hasEditPrivacyPolicy || this.hasEditTerms) &&
      this.edit
    ) {
      this.switchEditViewMode.emit();
    } else if (this.hasAddAdministrator || this.hasSaveProvider) {
      this.goBack.emit();
    } else {
      this.router.navigate(["/"]);
    }
  }

  goToAddNewAdministrator() {
    this.router.navigate(["/add-new-administrator"]);
  }

  goToAddNewProvider() {
    this.router.navigate(["/add-new-provider"]);
  }
}
