<div
  class="homepage-container"
  fxLayout.gt-md="row"
  fxLayoutAlign.gt-md="center start"
  fxLayout="column-reverse"
  fxLayoutAlign.gt-md="end start"
>
  <div class="container-wrappers" fxFlex="84">
    <div class="container main-menu-element-container">
      <h2 *ngIf="!isEdit" id="title" translate>lbl-add-administrator</h2>
      <h2 *ngIf="isEdit" id="title" translate>lbl-edit-administrator</h2>
      <p translate>lbl-create-new-administrator</p>
      <div class="form-container" fxLayout="column">
        <form
          fxFlex="90%"
          [formGroup]="administratorForm"
          (ngSubmit)="addNewAdministrator()"
          #documentEditForm="ngForm"
          novalidate
        >
          <h4 translate><span class="orange"></span>lbl-basic-information</h4>
          <div>
            <mat-form-field>
              <mat-label>{{ "lbl-first-name" | translate }}*</mat-label>
              <input formControlName="first_name" matInput />
              <mat-error
                *ngIf="
                  administratorForm.controls.first_name.invalid &&
                  administratorForm.controls.first_name.errors!.required
                "
                translate
                >error-first-name</mat-error
              >
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "lbl-last-name" | translate }}*</mat-label>
              <input formControlName="last_name" matInput />
              <mat-error
                *ngIf="
                  administratorForm.invalid &&
                  administratorForm.controls.last_name.errors?.required
                "
                translate
                >error-last-name</mat-error
              >
            </mat-form-field>
          </div>
          <div fxLayout="column">
            <mat-form-field>
              <mat-label>{{ "lbl-address" | translate }}*</mat-label>
              <input formControlName="address" matInput />
              <mat-error
                *ngIf="
                  administratorForm.controls.address.invalid &&
                  administratorForm.controls.address.errors!.required
                "
                translate
                >error-required-address</mat-error
              >
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>{{ "lbl-email" | translate }}*</mat-label>
              <input
                [disabled]="isEdit"
                formControlName="email"
                type="email"
                matInput
                placeholder="Email"
              />
              <mat-error *ngIf="false" translate> error-valid-email </mat-error>
              <mat-error
                *ngIf="
                  administratorForm.controls.email.invalid &&
                  administratorForm.controls.email.errors!.required
                "
                translate
                >error-email-required</mat-error
              >
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ "lbl-phone-number" | translate }}*</mat-label>
              <input
                matInput
                oninput="this.value=this.value.replace(/[^0-9\s]/g,'')"
                type="text"
                formControlName="tel_number"
              />
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasAddAdministrator]="true"
    [hasCancel]="true"
    (addAdministrator)="documentEditForm.ngSubmit.emit()"
    (goBack)="goBack()"
    fxFlex="13"
    fxFlex.md="10"
  ></app-sidebar-buttons>
</div>
