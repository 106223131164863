<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex.gt-md="84" fxFlex="82">
    <div class="container">
      <mat-card>
        <div
          class="title-container"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <h5 class="title" translate>
            <div class="orange"></div>
            lbl-latest-admin-users
          </h5>
          <h5 class="clickable" routerLink="/list-administrators" translate>
            lbl-view-all-short
          </h5>
        </div>
        <mat-table [dataSource]="providersAdmin">
          <ng-container matColumnDef="icon">
            <mat-header-cell
              fxFlex="90px"
              *matHeaderCellDef
              translate
            ></mat-header-cell>
            <mat-cell fxFlex="90px" *matCellDef="let element">
              <ngx-avatar
                name="{{ element.name }}"
                initialsSize="4"
              ></ngx-avatar>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell fxFlex="33" *matHeaderCellDef translate
              >lbl-name</mat-header-cell
            >
            <mat-cell fxFlex="33" *matCellDef="let element"
              ><div class="cell-name-wrapper">
                <div class="cell-name-elements">
                  <span
                    class="name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.name }}</span
                  >
                  <span
                    class="sub-name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.email }}</span
                  >
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="username">
            <mat-header-cell fxFlex="15" *matHeaderCellDef translate
              >lbl-username</mat-header-cell
            >
            <mat-cell fxFlex="15" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                >{{ element.username }}</span
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="address">
            <mat-header-cell fxFlex="30" *matHeaderCellDef translate
              >lbl-address</mat-header-cell
            >
            <mat-cell fxFlex="30" *matCellDef="let element"
              ><span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                >{{ element.address }}</span
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="tel_number">
            <mat-header-cell fxFlex="15" *matHeaderCellDef translate
              >lbl-phone-number</mat-header-cell
            >
            <mat-cell fxFlex="15" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{ element.tel_number }}
              </span></mat-cell
            >
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsAdmin"
          ></mat-header-row>
          <mat-row
            (click)="goToIndividualScreen(row, 'administrator')"
            *matRowDef="let row; columns: displayedColumnsAdmin"
          ></mat-row>
        </mat-table>
      </mat-card>
    </div>
    <mat-divider></mat-divider>
    <div class="container">
      <mat-card>
        <div
          class="title-container"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <h5 class="title" translate>
            <div class="orange"></div>
            lbl-latest-platform-users
          </h5>
          <h5 class="clickable" routerLink="/list-users" translate>
            lbl-view-all-short
          </h5>
        </div>
        <mat-table [dataSource]="providersUsers">
          <ng-container matColumnDef="icon">
            <mat-header-cell
              fxFlex="90px"
              *matHeaderCellDef
              translate
            ></mat-header-cell>
            <mat-cell fxFlex="90px" *matCellDef="let element">
              <ngx-avatar
                name="{{ element.name }}"
                initialsSize="4"
              ></ngx-avatar>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell fxFlex="40" *matHeaderCellDef translate
              >lbl-name</mat-header-cell
            >
            <mat-cell fxFlex="40" *matCellDef="let element"
              ><div class="cell-name-wrapper">
                <div class="cell-name-elements">
                  <span
                    class="name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.name }}</span
                  >
                  <span
                    class="sub-name ellipsis"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.email }}</span
                  >
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="username">
            <mat-header-cell fxFlex="15" *matHeaderCellDef translate
              >lbl-username</mat-header-cell
            >
            <mat-cell fxFlex="15" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{ element.username }}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="address">
            <mat-header-cell fxFlex="30" *matHeaderCellDef translate
              >lbl-address</mat-header-cell
            >
            <mat-cell fxFlex="30" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
                >{{ element.address }}</span
              ></mat-cell
            >
          </ng-container>
          <ng-container matColumnDef="tel_number">
            <mat-header-cell fxFlex="15" *matHeaderCellDef translate
              >lbl-phone-number</mat-header-cell
            >
            <mat-cell fxFlex="15" *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{ element.tel_number }}
              </span></mat-cell
            >
          </ng-container>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsUsers"
          ></mat-header-row>
          <mat-row
            (click)="goToIndividualScreen(row, 'platform-user')"
            *matRowDef="let row; columns: displayedColumnsUsers"
          ></mat-row>
        </mat-table>
      </mat-card>
    </div>
  </div>
  <app-sidebar-buttons
    fxFlex="40"
    fxFlex.gt-md="15"
    [hasAddAdministratorRedirect]="true"
    [hasAddProviderRedirect]="true"
  ></app-sidebar-buttons>
</div>
