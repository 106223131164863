import { Component, OnInit, Input } from "@angular/core";
import { I18nService } from "@app/i18n/i18n.service";

@Component({
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  styleUrls: ["./language-selector.component.scss"],
})
export class LanguageSelectorComponent implements OnInit {
  @Input() icon = false;

  constructor(private i18nService: I18nService) {}

  ngOnInit() {}

  setLanguage(language: string) {
    this.i18nService.language = language;
    window.location.reload();
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  getLanguageIcon(currLang: string) {
    if (currLang == "en-US") {
      return "flag:GB";
    } else if (currLang == "fr-FR") {
      return "flag:FR";
    } else {
      return "flag:PT";
    }
  }

  getAbbr(language: string) {
    if (language == "en-US") {
      return "en-US-short";
    } else {
      return "fr-FR-short";
    }
  }
}
