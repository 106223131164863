import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ForgotPasswordDialogComponent } from "../forgot-password-dialog/forgot-password-dialog.component";
import { DialogData } from "../types/dialog-data.interface";

@Component({
  selector: "app-are-you-sure",
  templateUrl: "./are-you-sure.component.html",
  styleUrls: ["./are-you-sure.component.scss"],
})
export class AreYouSureComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  
  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
  }

  delete() {
    this.submitClicked.emit();
    this.dialogRef.close();
  }
}
