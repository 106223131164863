import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";

import { HomeComponent } from "./home.component";
import { Shell } from "@app/shell/shell.service";
import { ListProvidersComponent } from "./list-providers/list-providers.component";
import { AddNewProviderComponent } from "./add-new-provider/add-new-provider.component";
import { AddNewAdministratorComponent } from "./add-new-administrator/add-new-administrator.component";
import { ListUsersComponent } from "./list-users/list-users.component";
import { AdministratorComponent } from "./administrator/administrator.component";
import { UserComponent } from "./user/user.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ListAdministratorsComponent } from "./list-administrators/list-administrators.component";
import { AboutComponent } from "./about/about.component";
import { PlatformUserComponent } from "./platform-user/platform-user.component";

const routes: Routes = [
  Shell.childRoutes([
    { path: "", redirectTo: "/home", pathMatch: "full" },
    { path: "home", component: HomeComponent, data: { title: marker("Home") } },
    {
      path: "list-providers",
      component: ListProvidersComponent,
      data: { title: marker("List Providers") },
    },
    {
      path: "add-new-provider",
      component: AddNewProviderComponent,
      data: { title: marker("Add new Provider") },
    },
    {
      path: "edit-provider/:id",
      component: AddNewProviderComponent,
      data: { title: marker("Edit Provider") },
    },
    {
      path: "add-new-administrator",
      component: AddNewAdministratorComponent,
      data: { title: marker("Add new Administrator") },
    },
    {
      path: "edit-administrator/:id",
      component: AddNewAdministratorComponent,
      data: { title: marker("Edit Administrator") },
    },
    {
      path: "list-users",
      component: ListUsersComponent,
      data: { title: marker("List Users") },
    },
    {
      path: "list-administrators",
      component: ListAdministratorsComponent,
      data: { title: marker("List Administrators") },
    },
    {
      path: "administrator/:id",
      component: AdministratorComponent,
      data: { title: marker("Administrator Overview") },
    },
    {
      path: "provider/:id",
      component: UserComponent,
      data: { title: marker("Medical Provider Overview") },
    },
    {
      path: "platform-user/:id",
      component: PlatformUserComponent,
      data: { title: marker("Platform User Overview") },
    },
    {
      path: "terms-of-use",
      component: TermsOfUseComponent,
      data: { title: marker("Terms Of Use") },
    },
    {
      path: "privacy-policy",
      component: PrivacyPolicyComponent,
      data: { title: marker("Privacy Policy") },
    },
    {
      path: "about",
      component: AboutComponent,
      data: { title: marker("About") },
    },
  ]),
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
