import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NavService } from "@app/@shared/services/nav.service";
import { NavItem } from "@app/@shared/interfaces/nav-item.interface";

@Component({
  selector: "app-menu-list-item",
  templateUrl: "./menu-list-item.component.html",
  styleUrls: ["./menu-list-item.component.scss"],
  animations: [
    trigger("indicatorRotate", [
      state("collapsed", style({ transform: "rotate(0deg)" })),
      state("expanded", style({ transform: "rotate(180deg)" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4,0.0,0.2,1)")
      ),
    ]),
  ],
})
export class MenuListItemComponent implements OnInit {
  @HostBinding("attr.aria-expanded") ariaExpanded: boolean = false;
  @Input() item!: NavItem;
  @Input() depth!: number;
  @Input() items!: NavItem[];

  @Output() expand: EventEmitter<any> = new EventEmitter();

  currentUrl: string = "";

  constructor(public navService: NavService, public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url?: string) => {
      if (url) {
        this.currentUrl = url;
      }

      if (this.item?.route && url) {
        // console.log(`Checking '/${this.item.route}' against '${url}'`);
        this.item.expanded = url.indexOf(`${this.item.route}`) === 0;
        this.ariaExpanded = this.item.expanded;
        // console.log(`${this.item.route} is expanded: ${this.expanded}`);
      }

      if (!this.item.route && this.item.children && url) {
        const item = this.item.children.find((element) => element.route == url);

        if (item) {
          this.item.expanded = true;
          this.ariaExpanded = this.item.expanded;
        } else {
          this.item.expanded = false;
          this.ariaExpanded = this.item.expanded;
        }
      }
    });
  }

  onItemSelected(item: NavItem) {
    if (!item.children || !item.children.length) {
      if (this.currentUrl == item.route) {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate([item.route]);
          });
      } else {
        this.router.navigate([item.route]);
      }
    }
    if (item.children && item.children.length) {
      this.item.expanded = !this.item.expanded;
    }
  }

  checkForChildrenRoutes(item: NavItem) {
    if (item.children) {
      for (let childItem of item.children) {
        if (childItem.route) {
          if (this.router.isActive(childItem.route, true)) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
