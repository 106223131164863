<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex.gt-md="87" fxFlex="80">
    <div *ngIf="medicalProvider" class="container main-menu-element-container">
      <div
        class="doctor-header"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div class="doctor-avatar-wrapper">
          <img
            *ngIf="medicalProvider.img"
            class="doctor-avatar"
            [src]="medicalProvider.img"
            alt=""
          />
          <ngx-avatar
            *ngIf="
              !medicalProvider.img &&
              medicalProvider?.type_of_practice.text != 'Clinic'
            "
            size="100"
            value="100%"
            [name]="
              medicalProvider?.first_name + ' ' + medicalProvider?.last_name
            "
            initialsSize="4"
          ></ngx-avatar>
          <ngx-avatar
            *ngIf="
              !medicalProvider.img &&
              medicalProvider?.type_of_practice.text == 'Clinic'
            "
            size="100"
            value="100%"
            [name]="medicalProvider.clinic_name"
            initialsSize="4"
          ></ngx-avatar>
          <img
            *ngIf="
              medicalProvider &&
              medicalProvider.practice &&
              medicalProvider.practice.is_eugen_recommended
            "
            class="eugen-recommeneded"
            src="../../../assets/ic_profile_eugen_recommended.svg"
            alt=""
          />
        </div>
        <h2
          id="title"
          *ngIf="medicalProvider?.type_of_practice.text != 'Clinic'"
        >
          {{ medicalProvider.first_name }} {{ medicalProvider.last_name }}
        </h2>
        <h2
          id="title"
          *ngIf="medicalProvider?.type_of_practice.text == 'Clinic'"
        >
          {{ medicalProvider.clinic_name }}
        </h2>
        <p class="subtitle">
          {{ medicalProvider?.type_of_practice.text | translate }}
        </p>
      </div>
      <h4 translate><span class="orange"></span>lbl-basic-information</h4>
      <div class="basic-info">
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-clinic-name</p>
          <h3>{{ medicalProvider.clinic_name || "-" }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-spoken-languages</p>
          <h3>{{ getLanguagesSpoken(medicalProvider.languages_spoken) }}</h3>
        </div>
        <div class="bubble" fxFlex="100%">
          <p translate>lbl-list-of-clinics</p>
          <h3>{{ medicalProvider.additional_clinics || "-" }}</h3>
        </div>
      </div>
      <h4 translate><span class="orange"></span>lbl-practice-information</h4>
      <div class="practice-info">
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-specialty</p>
          <h3>
            {{ getSpecialities(medicalProvider) }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-address</p>
          <h3>
            {{
              medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.location &&
                medicalProvider.practice.location.address
            }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-phone-number</p>
          <h3>
            {{
              medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.contact &&
                medicalProvider.practice.contact.tel_number
            }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-email</p>
          <h3>
            {{
              (medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.contact &&
                medicalProvider.practice.contact.email) ||
                "-"
            }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p>Website</p>
          <h3>
            {{
              (medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.contact &&
                medicalProvider.practice.contact.web_site) ||
                "-"
            }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-medical-service-range</p>
          <h3>
            &euro;{{
              medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.price_from
            }}
            - &euro;{{
              medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.price_to
            }}
          </h3>
        </div>
      </div>
      <div class="map">
        <agm-map
          [latitude]="+medicalProvider.practice.location.latitude"
          [longitude]="+medicalProvider.practice.location.longitude"
        >
          <agm-marker
            [latitude]="+medicalProvider.practice.location.latitude"
            [longitude]="+medicalProvider.practice.location.longitude"
            [iconUrl]="{
              url: '../../../assets/ic_map_pin_eugen.svg',
              scaledSize: {
                width: 35,
                height: 55
              }
            }"
          ></agm-marker>
        </agm-map>
      </div>
      <h4 translate><span class="orange"></span>lbl-working-hours</h4>
      <div class="practice-info">
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-monday</p>
          <h3>{{ getWorkingDaysFor("lbl-monday") || "-" }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-tuesday</p>
          <h3>{{ getWorkingDaysFor("lbl-tuesday") || "-" }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-wednesday</p>
          <h3>
            {{ getWorkingDaysFor("lbl-wednesday") || "-" }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-thursday</p>
          <h3>
            {{ getWorkingDaysFor("lbl-thursday") || "-" }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-friday</p>
          <h3>
            {{ getWorkingDaysFor("lbl-friday") || "-" }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-saturday</p>
          <h3>
            {{ getWorkingDaysFor("lbl-saturday") || "-" }}
          </h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-sunday</p>
          <h3>
            {{ getWorkingDaysFor("lbl-sunday") || "-" }}
          </h3>
        </div>
      </div>
      <h4 translate><span class="orange"></span>lbl-additional-information</h4>
      <div class="additional-info">
        <div class="bubble bubble-with-content" fxFlex="48%">
          <div class="bubble-content">
            <p translate>lbl-eugen-recommended</p>
            <h3
              *ngIf="
                medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.is_eugen_recommended
              "
            >
              {{ "lbl-yes" | translate }}
            </h3>
            <h3
              *ngIf="
                !medicalProvider ||
                !medicalProvider.practice ||
                !medicalProvider.practice.is_eugen_recommended
              "
            >
              {{ "lbl-no" | translate }}
            </h3>
          </div>
          <img
            class="bubble-image"
            src="../../../assets/ic_eugen_recommended.svg"
            alt=""
          />
        </div>
        <div class="bubble bubble-with-content" fxFlex="48%">
          <div class="bubble-content">
            <p translate>lbl-moneytrack-supported</p>
            <h3>
              {{
                medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.is_moneytrack_supported
                  ? ("lbl-yes" | translate)
                  : ("lbl-no" | translate)
              }}
            </h3>
          </div>
          <img
            class="bubble-image"
            src="../../../assets/ic_moneytrack_supported.svg"
            alt=""
          />
        </div>
        <div class="full-width-bubble" fxFlex="100%">
          <p translate>lbl-additional-notes</p>
          <h3>
            {{
              (medicalProvider &&
                medicalProvider.practice &&
                medicalProvider.practice.additional_note) ||
                "-"
            }}
          </h3>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasEditInformation]="true"
    [hasDeleteProfile]="true"
    (editInformation)="editInformation()"
    (deleteProfile)="deleteProfileHandler()"
    fxFlex="30"
    fxFlex.gt-md="13"
  ></app-sidebar-buttons>
</div>
