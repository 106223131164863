import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthenticationService } from "@app/auth/authentication.service";
import { TranslateService } from "@ngx-translate/core";
import { DialogData } from "../types/dialog-data.interface";

@Component({
  selector: "app-forgot-password-dialog",
  templateUrl: "./forgot-password-dialog.component.html",
  styleUrls: ["./forgot-password-dialog.component.scss"],
})
export class ForgotPasswordDialogComponent implements OnInit {
  isLoading: boolean = false;
  loginForm!: FormGroup;

  @Output() submitClicked = new EventEmitter<any>();

  constructor(
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.createForm();
  }

  ngOnInit(): void {}

  login() {
    this.isLoading = true;
    this.authenticationService
      .sendEmailToResetPassword(this.loginForm.value.username)
      .subscribe(
        (response: any) => {
          if (!response.error) {
            this.data.usernameSent = true;
            this.submitClicked.emit(this.data);
            this.isLoading = false;
            this.dialogRef.close();
          } else {
            this._snackBar.open(
              this.translateService.instant("error-email-invalid"),
              this.translateService.instant("lbl-cancel"),
              {
                panelClass: ["error-snackbar"],
              }
            );
            this.isLoading = false;
          }
        },
        (error) => {
          this._snackBar.open(
            this.translateService.instant("error-email-invalid"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["error-snackbar"],
            }
          );
          console.log("Error", error);
          this.isLoading = false;
        }
      );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: [
        "",
        Validators.compose([Validators.required, Validators.email]),
      ],
    });
  }
}
