import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { MaterialModule } from "@app/material.module";
import { LoaderComponent } from "./loader/loader.component";
import { LogoComponent } from "./components/logo/logo.component";
import { ForgotPasswordDialogComponent } from "./components/forgot-password-dialog/forgot-password-dialog.component";
import { ReactiveFormsModule } from "@angular/forms";
import { EmailSentDialogComponent } from "./components/email-sent-dialog/email-sent-dialog.component";
import { MenuListItemComponent } from "./components/menu-list-item/menu-list-item.component";
import { NavService } from "./services/nav.service";
import { SliderComponent } from "./components/slider/slider.component";
import { DndDirective } from "./directives/dnd.directive";
import { ProgressComponent } from "./components/progress/progress.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { NavbarDetailsComponent } from "./components/navbar-details/navbar-details.component";
import { LanguageSelectorComponent } from "./components/language-selector/language-selector.component";
import { SidebarButtonsComponent } from "./components/sidebar-buttons/sidebar-buttons.component";
import { ReadMorePipe } from "./pipes/read-more.pipe";
import { AreYouSureComponent } from './components/are-you-sure/are-you-sure.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoaderComponent,
    LogoComponent,
    ForgotPasswordDialogComponent,
    EmailSentDialogComponent,
    MenuListItemComponent,
    MenuListItemComponent,
    SliderComponent,
    DndDirective,
    ProgressComponent,
    SafeHtmlPipe,
    ReadMorePipe,
    NavbarDetailsComponent,
    LanguageSelectorComponent,
    SidebarButtonsComponent,
    AreYouSureComponent,
  ],
  exports: [
    LoaderComponent,
    LogoComponent,
    ForgotPasswordDialogComponent,
    EmailSentDialogComponent,
    MenuListItemComponent,
    SliderComponent,
    ProgressComponent,
    SafeHtmlPipe,
    ReadMorePipe,
    NavbarDetailsComponent,
    LanguageSelectorComponent,
    SidebarButtonsComponent,
  ],
  providers: [NavService],
})
export class SharedModule {}
