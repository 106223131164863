import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";

import { Logger, UntilDestroy, untilDestroyed } from "@shared";
import { AuthenticationService } from "./authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { ForgotPasswordDialogComponent } from "@app/@shared/components/forgot-password-dialog/forgot-password-dialog.component";
import { EmailSentDialogComponent } from "@app/@shared/components/email-sent-dialog/email-sent-dialog.component";
import { DialogData } from "@app/@shared/components/types/dialog-data.interface";
import { CredentialsService } from ".";

const log = new Logger("Login");

@UntilDestroy()
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  hide: boolean = true;

  get passwordInput() {
    return this.loginForm.get("password");
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    public dialog: MatDialog
  ) {
    this.createForm();
    if (this.credentialsService.isAuthenticated()) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit() {}

  login() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    const login$ = this.authenticationService.login(this.loginForm.value);

    login$
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        (credentials) => {
          log.debug(`${credentials.username} successfully logged in`);
          this.router.navigate(
            [this.route.snapshot.queryParams.redirect || "/"],
            { replaceUrl: true }
          );
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ["", Validators.required],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          // Validators.pattern(
          //   "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d@$!%*#?&^_-]{8,}$"
          // ),
        ],
      ],
    });
  }

  openForgotPasswordModal(): void {
    const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {
      autoFocus: false,
      data: { usernameSent: false },
      width: "450px",
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe(
        (result: DialogData) => {
          if (result.usernameSent == true) {
            this.dialog.open(EmailSentDialogComponent);
          }
          dialogSubmitSubscription.unsubscribe();
        }
      );
  }
}
