import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ModelsService } from "@app/@shared/services/models.service";
import { I18nService } from "@app/i18n";
import { TranslateService } from "@ngx-translate/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { forkJoin } from "rxjs";
import {saveAs} from "file-saver";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { HomeService } from "../home.service";


@Component({
  selector: "app-list-providers",
  templateUrl: "./list-providers.component.html",
  styleUrls: ["./list-providers.component.scss"],
})
export class ListProvidersComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];

  medicalProviders = [{}];
  searchEntityCtrl = new FormControl("");
  practices = this.modelsService.practices.value;

  specialities = this.modelsService.specialities.value;

  sortBy: string = "date_added";
  totalItems: number = 0;
  data = {
    offset: 0,
    limit: "10",
    page: 1,
    sortBy: "",
    search: "",
    practiceId: null,
    specialityId: null,
    address: ""
  };

  providers: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns: string[] = [
    "icon",
    "name",
    "nameOfPractice",
    "typeOfPractice",
    "address",
    "tel_number",
  ];

  loaded: boolean = false;
  appLanguage: string = "";
  selectLang: string = "";

  constructor(
    private modelsService: ModelsService,
    private homeService: HomeService,
    private router: Router,
    private ngxLoader: NgxUiLoaderService,
    private i18nService: I18nService,
    private translateService: TranslateService
  ) {
    this.appLanguage = this.i18nService.language;
    this.selectLang = this.appLanguage.split('-')[0];
    this.getMedicalProviderData();

    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });

    this.modelsService.searchData.subscribe((value) => {
      this.data.search = value;
      this.getMedicalProviderData();
    });
  }

  ngOnInit(): void {
    this.searchEntityCtrl.valueChanges
        .pipe(debounceTime(600), distinctUntilChanged())
        .subscribe((searchInput: any) => {
          this.data.address = searchInput;
          this.getAllMedicalProviders();
        });
  }

  sortTableBy(event: any) {
    let _sortBy = event.value;
    this.data.sortBy = _sortBy;
    this.getAllMedicalProviders();
  }

  viewSearch(event: any) {
    if (event.keyCode == 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  handlePracticeChange(event: any) {
    this.data.practiceId = event.value;

    this.getAllMedicalProviders();
  }

  handleSpecialityChange(event: any) {
    this.data.specialityId = event.value;

    this.getAllMedicalProviders();
  }

  exportMedicalProviders() {
    this.homeService.exportMedicalProviders(this.data, this.appLanguage).subscribe(buffer => {
      const data: Blob = new Blob([buffer], {
        type: "text/csv;charset=utf-8"
      });

      saveAs(data, "medical-providers.csv");
    });
  }

  onPageChange(event: any) {
    this.medicalProviders = [];
    this.data.page = event;
    if (this.data.page == 0) {
      this.data.offset = 0;
    } else {
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllMedicalProviders();
  }

  getAllMedicalProviders() {
    this.ngxLoader.startLoader("homepage-loader");
    this.homeService
      .getAllMedicalProviders(this.data, this.appLanguage)
      .subscribe(
        (response) => {
          this.totalItems = response.count;
          this.medicalProviders = response.results;
          this.ngxLoader.stopLoader("homepage-loader");
          this.loaded = true;
        },
        (error) => {
          this.loaded = true;
        }
      );
  }

  goToIndividualScreen(entity: any) {
    this.router.navigate(["/provider", entity.id]);
  }

  showTableBy(event: any) {
    let _showBy = event.value;
    this.data.limit = _showBy;
    if (this.totalItems < +this.data.limit * this.data.page) {
      this.data.page = Math.ceil(this.totalItems / +this.data.limit);
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllMedicalProviders();
  }

  retrieveSpeciality(type: string) {
    if (this.modelsService.specialities.value.length) {
      return this.modelsService.specialities.value.find(
        (speciality) => speciality.text == "English"
      ).text;
    }

    return "";
  }

  navigateToAddProvider() {
    this.router.navigate(["/add-new-provider"]);
  }

  getMedicalProviderData() {
    let requests = [];
    if (this.modelsService.languages.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersLanguages());
    }

    if (this.modelsService.practices.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersPractices());
    }

    if (this.modelsService.specialities.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersSpecialities());
    }

    if (requests.length != 0) {
      forkJoin(requests).subscribe((responses) => {
        this.modelsService.languages.next(responses[0]);
        this.modelsService.practices.next(responses[1]);
        this.modelsService.specialities.next(responses[2]);

        this.practices = responses[1];
        this.specialities = responses[2];

        this.getAllMedicalProviders();
      });
    } else {
      this.getAllMedicalProviders();
    }
  }

  mouseEnter(event: any) {
    let element = event.srcElement;
    if (element) {
      element.setAttribute("title", element.innerHTML);
    }
  }

  mouseLeave(event: any) {}
}
