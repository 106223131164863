<div
  fxFill
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center center"
  class="mat-typography"
>
  <div
    fxFill
    fxLayout="column"
    fxFlex="30%"
    fxFlex.xs="100%"
    class="logo-wrapper"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="2rem"
      class="title-and-language"
    >
      <app-logo size="large"></app-logo>
    </div>

    <div
      class="login-container-wrapper"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="login-container" fxLayoutAlign="center center">
        <form
          (ngSubmit)="resetPassword()"
          fxFlex="90%"
          [formGroup]="passwordForm"
          novalidate
        >
          <div *ngIf="resetSuccessful" class="login-box">
            <h2 class="login" translate>lbl-success</h2>
            <p translate>lbl-password-updated</p>
          </div>
          <div *ngIf="!resetSuccessful" class="login-box">
            <h2 *ngIf="!isReset" class="login" translate>lbl-reset-password</h2>
            <h2 *ngIf="isReset" class="login" translate>lbl-create-password</h2>
            <p *ngIf="!isReset" translate>lbl-enter-password-reset</p>
            <p *ngIf="isReset" translate>lbl-enter-password-create</p>
            <div class="login-error" [hidden]="!error || isLoading" translate>
              error-occured
            </div>
            <br />
            <div class="login-fields" fxLayout="column">
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  type="password"
                  matInput
                  formControlName="newPassword"
                  autocomplete="current-password"
                  [placeholder]="'lbl-enter-new-password' | translate"
                  [type]="hide ? 'password' : 'text'"
                  required
                />
                <mat-error
                  *ngIf="
                    passwordForm.controls.newPassword.invalid &&
                    passwordForm.controls.newPassword.touched &&
                    passwordForm.controls.newPassword.errors!.pattern
                  "
                >
                  <span translate>error-new-password-form</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  type="password"
                  matInput
                  formControlName="repeatPassword"
                  autocomplete="current-password"
                  [placeholder]="'lbl-repeat-password' | translate"
                  [type]="hide ? 'password' : 'text'"
                  required
                />
                <mat-icon
                  class="mat-icon-visibility"
                  matSuffix
                  (mouseenter)="hide = !hide"
                  (mouseleave)="hide = !hide"
                  >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
                >
                <mat-error
                  *ngIf="
                    passwordForm.controls.repeatPassword.invalid &&
                    passwordForm.controls.repeatPassword.touched &&
                    passwordForm.controls.repeatPassword.errors!.required
                  "
                >
                  <span translate>lbl-password-required</span>
                </mat-error>
                <mat-error
                  *ngIf="
                    passwordForm.controls.repeatPassword.invalid &&
                    passwordForm.controls.repeatPassword.touched &&
                    passwordForm.controls.repeatPassword.errors!.pattern
                  "
                >
                  <span translate>error-new-password-form</span>
                </mat-error>
                <mat-error
                  *ngIf="
                    passwordForm.controls.repeatPassword.touched &&
                    passwordForm.controls.newPassword.touched &&
                    passwordForm.controls.repeatPassword.errors?.mustMatch
                  "
                >
                  <span translate>error-password-match</span>
                </mat-error>
              </mat-form-field>
              <br />
            </div>
          </div>
          <div
            *ngIf="!resetSuccessful"
            class="auth-button-border"
            fxLayoutAlign="center center"
          >
            <button
              mat-button
              color="primary"
              id="auth-button"
              class="login-page-button"
              type="submit"
              [disabled]="passwordForm.invalid || isLoading"
            >
              <app-loader
                class="inline-loader"
                [isLoading]="isLoading"
              ></app-loader>
              <span *ngIf="!isLoading && !isReset" translate
                >lbl-reset-password</span
              >
              <span *ngIf="!isLoading && isReset" translate
                >lbl-create-password</span
              >
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div fxFlex="70%" fxFlex.xs="100%" class="image-wrapper"></div>
</div>
