import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit {
  @Input() checkboxName: string = "";
  @Input() nameOfControl?: string = "";
  @Input() form?: FormGroup;
  @Output() isChecked = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  checkCheckBoxvalue(event: any) {
    this.isChecked.emit(event.target.checked);
  }
}
