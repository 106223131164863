import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Logger} from "@app/@shared/logger.service";
import {environment} from "@env/environment";
import {throwError} from "rxjs";
import {map} from "rxjs/operators";

const log = new Logger("HomeService");

@Injectable({
    providedIn: "root",
})
export class HomeService {
    constructor(protected http: HttpClient, protected router: Router) {
    }

    /*
     * GET METHODS
     */
    getAllUsers(data?: any) {
        let params = {};

        if (data.limit) {
            params["limit"] = data.limit;
        }

        if (data.offset) {
            params["offset"] = data.offset;
        }

        if (data.search) {
            params["search"] = data.search;
        }

        if (data.sortBy) {
            params["ordering"] = data.sortBy;
        }

        return this.http
            .get(`${environment.serverUrl}/api/v1/account/`, {params: params})
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getAllUsers Response", response);
                        if (response && response.results) {
                            response.results.map((element: any) => {
                                element.name = element.first_name + " " + element.last_name;
                            });
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getAllAdmins(data?: any) {
        let params = {};

        if (data.limit) {
            params["limit"] = data.limit;
        }

        if (data.offset) {
            params["offset"] = data.offset;
        }

        if (data.search) {
            params["search"] = data.search;
        }

        if (data.sortBy) {
            params["ordering"] = data.sortBy;
        }

        return this.http
            .get(`${environment.serverUrl}/api/v1/account/admin/`, {params: params})
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getAllAdmins Response", response);
                        if (response && response.results) {
                            response.results.map((element: any) => {
                                element.name = element.first_name + " " + element.last_name;
                            });
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getAllMedicalProviders(data: any, language: string) {
        let params = {};

        if (data.limit) {
            params["limit"] = data.limit;
        }

        if (data.offset) {
            params["offset"] = data.offset;
        }

        if (data.search) {
            params["search"] = data.search;
        }

        if (data.sortBy) {
            params["ordering"] = data.sortBy;
        }

        if (data.practiceId) {
            params["type_of_practice__ID"] = data.practiceId;
        }

        if (data.specialityId) {
            params["practice__medical_speciality__ID"] = data.specialityId;
        }

        if (data.address) {
            params["practice__location__address__contains"] = data.address;
        }

        let headers = new HttpHeaders({
            "accept-language": language,
        });

        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/`, {
                params: params,
                headers: headers,
            })
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getAllMedicalProviders Response", response);
                        if (response && response.results) {
                            response.results.map((element: any) => {
                                element.name = element.first_name + " " + element.last_name;
                            });
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getAdminById(id: number) {
        return this.http
            .get(`${environment.serverUrl}/api/v1/account/admin/${id}/`)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("getAdminById response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getPlatformUserById(id: number) {
        return this.http.get(`${environment.serverUrl}/api/v1/account/${id}/`).pipe(
            map(
                (response: any) => {
                    if (response) {
                        log.debug("getUserById response", response);
                        return response;
                    }
                },
                (error: Error) => {
                    throwError(error);
                }
            )
        );
    }

    getMedicalProviderById(id: number) {
        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/${id}/`)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("getMedicalProviderById response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    /*
     * END OF GET METHODS
     */

    /*
     * POST METHODS
     */
    postNewAdmin(data: any) {
        return this.http
            .post(`${environment.serverUrl}/api/v1/account/admin/`, data)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("postNewAdmin response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    postNewProvider(providerData: any) {
        return this.http
            .post(`${environment.serverUrl}/api/v1/medical-providers/`, providerData)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("postNewProvider response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    updateProvider(providerId: string, providerData: any) {
        return this.http
            .patch(
                `${environment.serverUrl}/api/v1/medical-providers/${providerId}/`,
                providerData
            )
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("updateProvider response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    deleteMedicalProvider(id: string) {
        return this.http
            .delete(`${environment.serverUrl}/api/v1/medical-providers/${id}/`)
            .pipe(
                map(
                    (response: any) => {
                        log.debug("deleteMedicalProvider Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }


    updateAdministrator(administratorId: string, administratorData: any) {
        return this.http
            .patch(
                `${environment.serverUrl}/api/v1/account/admin/${administratorId}/`,
                administratorData
            )
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("updateAdministrator response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    postImageForProvider(userId: string, image: any) {
        return this.http
            .put(
                `${environment.serverUrl}/api/v1/medical-providers/${userId}/image/`,
                image
            )
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            log.debug("postImageForProvider response", response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    /*
     * END OF POST METHODS  METHODS
     */

    /*
     * TERMS OF USE, PRIVACY POLICY, ABOUT
     */
    getTermsOfUse() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/legal-notice/terms-of-use/`)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    saveTermsOfUse(data: any) {
        return this.http
            .post(`${environment.serverUrl}/api/v1/legal-notice/terms-of-use/`, {
                ...data,
            })
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getPrivacyPolicy() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/legal-notice/privacy-policy/`)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    savePrivacyPolicy(data: any) {
        return this.http
            .post(`${environment.serverUrl}/api/v1/legal-notice/privacy-policy/`, {
                ...data,
            })
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getAboutUs() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/legal-notice/about-us/`)
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    saveAboutUs(data: any) {
        return this.http
            .patch(`${environment.serverUrl}/api/v1/legal-notice/about-us/`, {
                ...data,
            })
            .pipe(
                map(
                    (response: any) => {
                        if (response) {
                            console.log(response);
                            return response;
                        }
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    /*
     * END OFTERMS OF USE, PRIVACY POLICY, ABOUT
     */

    /* Medical Providers Additional Data */
    getMedicalProvidersLanguages() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/languages/`)
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getMedicalProvidersLanguages Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getMedicalProvidersSpecialities() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/speciality/`)
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getMedicalProvidersSpecialities Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    getMedicalProvidersPractices() {
        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/practice/`)
            .pipe(
                map(
                    (response: any) => {
                        log.debug("getMedicalProvidersPractices Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    requestNewPassword(email: string) {
        let data = {
            email: email,
        };
        return this.http
            .post(
                `${environment.serverUrl}/api/v1/account/password/request-new/`,
                data
            )
            .pipe(
                map(
                    (response: any) => {
                        log.debug("requestNewPassword Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    toggleEnabled(id: number, isEnabled: boolean) {
        let data = {
            is_active: isEnabled,
        };
        return this.http
            .put(
                `${environment.serverUrl}/api/v1/account/admin/${id}/activate/`,
                data
            )
            .pipe(
                map(
                    (response: any) => {
                        log.debug("toggleEnabled Response", response);
                        return response;
                    },
                    (error: Error) => {
                        throwError(error);
                    }
                )
            );
    }

    exportMedicalProviders(data: any, language: string) {
        let params = {};

        if (data.limit) {
            params["limit"] = data.limit;
        }

        if (data.offset) {
            params["offset"] = data.offset;
        }

        if (data.search) {
            params["search"] = data.search;
        }

        if (data.sortBy) {
            params["ordering"] = data.sortBy;
        }

        if (data.practiceId) {
            params["type_of_practice__ID"] = data.practiceId;
        }

        if (data.specialityId) {
            params["practice__medical_speciality__ID"] = data.specialityId;
        }

        if (data.address) {
            params["practice__location__address__contains"] = data.address;
        }

        let headers = new HttpHeaders({
            "accept-language": language,
        });

        return this.http
            .get(`${environment.serverUrl}/api/v1/medical-providers/export/`, {
                params: params,
                headers: headers,
                responseType: "text"
            })
    }

    exportAllUsers(data?: any) {
        let params = {};

        if (data.limit) {
            params["limit"] = data.limit;
        }

        if (data.offset) {
            params["offset"] = data.offset;
        }

        if (data.search) {
            params["search"] = data.search;
        }

        if (data.sortBy) {
            params["ordering"] = data.sortBy;
        }

        return this.http
            .get(`${environment.serverUrl}/api/v1/account/export/`, {params: params,  responseType: "text"})
    }
}
