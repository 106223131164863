<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex="88">
    <div class="container main-menu-element-container">
      <label class="switch-language" for="terms-of-use">
        <input
          type="checkbox"
          id="terms-of-use"
          (change)="checkCheckBoxvalue($event)"
          [checked]="this.language == 'fr-FR'"
        />
        <div class="slider round"></div>
        <span
          class="english"
          [ngClass]="{ 'french-selected': appLanguage == 'fr-FR' }"
          translate
          >lbl-english-version</span
        >
        <span
          class="french"
          [ngClass]="{ 'french-selected': appLanguage == 'fr-FR' }"
          translate
          >lbl-french-version</span
        >
      </label>

      <p id="title" translate>lbl-eugen-terms-of-use</p>
      <div class="form-container" fxLayout="column">
        <ng-container *ngIf="language == 'fr-FR'">
          <p
            *ngIf="view && termsOfUse?.text_fr"
            [innerHTML]="termsOfUse?.text_fr | safeHtml"
          ></p>
          <form *ngIf="edit" [formGroup]="frenchTermsOfUseForm" novalidate>
            <div class="quill-container">
              <quill-editor
                [modules]="editorOptions"
                formControlName="frenchTermsOfUse"
              ></quill-editor>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="language == 'en-US'">
          <p
            *ngIf="view && termsOfUse?.text_en"
            [innerHTML]="termsOfUse?.text_en | safeHtml"
          ></p>
          <form *ngIf="edit" [formGroup]="englishTermsOfUseForm" novalidate>
            <div class="quill-container">
              <quill-editor
                [modules]="editorOptions"
                formControlName="englishTermsOfUse"
              ></quill-editor>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasEditTerms]="true"
    [hasSaveChanges]="true"
    [hasCancel]="edit"
    [edit]="edit"
    [view]="view"
    (switchEditViewMode)="goBack()"
    (editTermsOfUse)="editTermsOfUse()"
    (saveAbout)="saveTermsOfUse()"
    fxFlex="30"
    fxFlex.gt-sm="12"
  ></app-sidebar-buttons>
</div>
