import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "@app/@shared/services/loader.service";
import { I18nService } from "@app/i18n";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../home.service";
export interface AboutUsResponse {
  created_at: Date;
  id: number;
  text?: string;
  text_fr?: string;
  text_en?: string;
}

export interface AboutUsRequest {
  text_fr?: string;
  text_en?: string;
}

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
  editorOptions = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["link", "image"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: 1 }, { header: 2 }], // custom button values
    ],
  };

  frenchAboutUsForm!: FormGroup;
  englishAboutUsForm!: FormGroup;
  aboutUs?: AboutUsResponse;
  language: string = "en-US";
  appLanguage: string = "";

  view: boolean = true;
  edit: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {
    this.appLanguage = this.i18nService.language;
    this.language = this.appLanguage;
  }

  async ngOnInit() {
    await this.getAbout();
    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });
  }

  /**
   * Get About For English and French
   */
  getAbout() {
    this.loaderService.shouldRunLoader.next(true);
    this.homeService.getAboutUs().subscribe(
      (response) => {
        this.removeExtraNewlilne(response);
        this.aboutUs = response;
        this.loaderService.shouldRunLoader.next(false);
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);

        console.log(error);
      }
    );
  }

  /**
   * Create Form Function
   */
  createForm() {
    this.englishAboutUsForm = this.formBuilder.group({
      englishAboutUs: [this.aboutUs?.text_en || "", Validators.required],
    });

    this.frenchAboutUsForm = this.formBuilder.group({
      frenchAboutUs: [this.aboutUs?.text_fr || "", Validators.required],
    });
  }

  editAbout() {
    this.createForm();
    this.view = false;
    this.edit = true;
  }

  checkCheckBoxvalue(event: any) {
    if (event.target.checked) {
      this.language = "fr-FR";
      this.view = true;
      this.edit = false;
    } else {
      this.language = "en-US";
      this.view = true;
      this.edit = false;
    }
  }

  goBack() {
    this.view = true;
    this.edit = false;
  }

  removeExtraNewlilne(response: any) {
    let spaceSign = /(<p><br><\/p>)+/gi;
    response.text_fr = response.text_fr.replace(spaceSign, "<p><br></p>");
    response.text_en = response.text_en.replace(spaceSign, "<p><br></p>");
  }

  /**
   * send AboutUs home
   */
  saveAbout() {
    let data: AboutUsRequest = {};
    if (this.language == "fr-FR") {
      data.text_fr = this.frenchAboutUsForm.get("frenchAboutUs")!.value;
    } else if (this.language == "en-US") {
      data.text_en = this.englishAboutUsForm.get("englishAboutUs")!.value;
    }
    this.loaderService.shouldRunLoader.next(true);
    this.homeService.saveAboutUs(data).subscribe(
      (response) => {
        this.loaderService.shouldRunLoader.next(false);
        this.getAbout();
        this.goBack();
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);

        console.log(error);
      }
    );
  }
}
