<div class="creation-buttons">
  <div class="button-wrapper" fxLayout="column" fxLayoutAlign="center center">
    <div
      *ngIf="hasAddAdministrator"
      class="button-border"
    >
      <button
        mat-button
        color="primary"
        (click)="addNewAdministrator()"
        id="sidebar-confirm-button"
      >
        <span translate>lbl-save-administrator</span>
      </button>
    </div>
    <div *ngIf="hasEditAboutUs && view" class="button-border">
      <button
        mat-button
        color="primary"
        id="sidebar-confirm-button"
        (click)="editAboutClicked()"
      >
        <span translate>lbl-edit-about-us</span>
      </button>
    </div>
    <div *ngIf="hasEditPrivacyPolicy && view" class="button-wrapper">
      <div class="button-border">
        <button
          mat-button
          color="primary"
          id="sidebar-confirm-button"
          (click)="editPrivacyPolicyClicked()"
        >
          <span translate>lbl-edit-privacy-policy</span>
        </button>
      </div>
    </div>
    <div *ngIf="hasEditInformation" class="button-border">
      <button
        mat-button
        (click)="editInformationClicked()"
        id="sidebar-confirm-button"
        class="warning"
      >
        <span translate>lbl-edit-information</span>
      </button>
    </div>
    <div *ngIf="hasSaveProvider" class="button-border">
      <button
        form="providerForm"
        mat-button
        color="primary"
        type="submit"
        id="sidebar-confirm-button"
      >
        <span translate>lbl-confirm-save</span>
      </button>
    </div>

    <div *ngIf="hasEditTerms && view" class="button-wrapper">
      <div class="button-border">
        <button
          mat-button
          color="primary"
          id="sidebar-confirm-button"
          (click)="editTermsOfUseClicked()"
        >
          <span translate>lbl-edit-terms-of-use</span>
        </button>
      </div>
    </div>

    <div
      *ngIf="(hasEditTerms || hasEditAboutUs || hasEditPrivacyPolicy) && edit"
      class="button-border"
    >
      <button
        mat-button
        type="submit"
        color="primary"
        id="sidebar-confirm-button"
        (click)="saveAboutClicked()"
      >
        <span translate>lbl-save-changes</span>
      </button>
    </div>
    <div *ngIf="hasCancel" class="button-border">
      <button
        mat-button
        color="primary"
        id="sidebar-cancel-button"
        (click)="goToIndex()"
      >
        <span translate>lbl-cancel</span>
      </button>
    </div>
    <div *ngIf="hasResetPassword" class="button-border">
      <button
        mat-button
        (click)="resetPasswordClicked()"
        id="sidebar-cancel-button"
        class="warning"
      >
        <span translate>lbl-reset-password</span>
      </button>
    </div>

    <div
      *ngIf="hasAddAdministratorRedirect && isCurrAdministratorSuperUser"
      class="button-border"
    >
      <button
        (click)="goToAddNewAdministrator()"
        mat-button
        color="primary"
        id="sidebar-confirm-button"
      >
        <app-loader class="inline-loader"></app-loader>
        <span translate>lbl-add-administrator-plus</span>
      </button>
    </div>

    <div *ngIf="hasAddProviderRedirect" class="button-border">
      <button
        (click)="goToAddNewProvider()"
        mat-button
        color="primary"
        id="sidebar-confirm-button"
      >
        <app-loader class="inline-loader"></app-loader>
        <span translate>lbl-add-medical-provider-plus</span>
      </button>
    </div>

    <div *ngIf="hasSuspendProfile" class="button-border">
      <button
        mat-button
        (click)="suspendProfileClicked()"
        id="sidebar-suspend-button"
      >
        <span translate>lbl-suspend-profile</span>
      </button>
    </div>
    <div *ngIf="hasEnableProfile" class="button-border">
      <button
        mat-button
        (click)="enableProfileClicked()"
        id="sidebar-cancel-button"
      >
        <span translate>lbl-enable-profile</span>
      </button>
    </div>
    <div *ngIf="hasDeleteProfile" class="button-border">
      <button
        mat-button
        (click)="deleteProfileClicked()"
        id="sidebar-suspend-button"
      >
        <span translate>lbl-delete</span>
      </button>
    </div>
  </div>
</div>
