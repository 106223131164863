<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex="84">
    <div class="container main-menu-element-container">
      <h2 *ngIf="!isEdit" id="title" translate>lbl-add-new-provider</h2>

      <h2 *ngIf="isEdit" id="title" translate>lbl-edit-provider</h2>

      <p translate>lbl-add-provider-desc</p>
      <div class="form-container" fxLayout="column">
        <form
          (ngSubmit)="addNewProvider()"
          id="providerForm"
          fxFlex="90%"
          #ngForm="ngForm"
          [formGroup]="providerForm"
          novalidate
        >
          <h4 translate><span class="orange"></span>lbl-type-of-practice</h4>
          <mat-form-field>
            <mat-label
              >{{ "lbl-select-type-of-practice" | translate }}*</mat-label
            >
            <mat-select
              *ngIf="practices"
              matSelect
              formControlName="type_of_practice"
              (selectionChange)="practiceChanged($event.value)"
            >
              <mat-option
                *ngFor="let practice of practices"
                [value]="practice.ID"
              >
                {{ practice.text | translate }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                providerForm.get('type_of_practice')?.invalid &&
                providerForm.get('type_of_practice')?.errors?.required
              "
              translate
              >error-select-practicioner</mat-error
            >
          </mat-form-field>
          <h4 translate><span class="orange"></span>lbl-basic-information</h4>

          <mat-form-field>
            <mat-label
              >{{ "lbl-first-name" | translate
              }}{{ currentPractice != 2 ? "*" : "" }}</mat-label
            >
            <input formControlName="first_name" matInput />
            <mat-error
              *ngIf="
                providerForm.controls.first_name.invalid &&
                providerForm.controls.first_name.errors!.required
              "
              translate
              >error-first-name</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label
              >{{ "lbl-last-name" | translate
              }}{{ currentPractice != 2 ? "*" : "" }}</mat-label
            >
            <input formControlName="last_name" matInput />
            <mat-error
              *ngIf="
                providerForm.controls.last_name.invalid &&
                providerForm.controls.last_name.errors!.required
              "
              translate
              >error-last-name</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label
              >{{ "lbl-clinic-name" | translate
              }}{{ currentPractice == 2 ? "*" : "" }}</mat-label
            >
            <input matInput formControlName="clinic_name" />
            <mat-error
              *ngIf="
                providerForm.controls.clinic_name.invalid &&
                providerForm.controls.clinic_name.errors!.required
              "
              translate
              >error-valid-clinic-name</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label translate>lbl-list-of-clinics</mat-label>
            <input matInput formControlName="additional_clinics" />
            <mat-error
              *ngIf="
                providerForm.controls.additional_clinics.invalid &&
                providerForm.controls.additional_clinics.errors!.required
              "
              translate
              >error-list-of-clinics</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label translate>lbl-select-language</mat-label>
            <mat-select
              *ngIf="languages"
              formControlName="languages"
              mat-select
              multiple
            >
              <mat-option
                *ngFor="let language of languages"
                [value]="language.ID"
              >
                {{ language.text | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            for="fileDropRef"
            class="dnd-container"
            appDnd
            (fileDropped)="onFileDropped($event)"
          >
            <input
              type="file"
              #fileDropRef
              id="fileDropRef"
              (change)="fileBrowseHandler($event.target)"
            />
            <img src="../../../assets/img_upload_image.svg" alt="" />
            <p class="img-upload-title" translate>lbl-upload-image</p>
            <p translate>lbl-drag-drop</p>
          </div>
          <div class="files-list">
            <div class="single-file" *ngIf="files[0]">
              <div class="info">
                <h4 class="name">
                  {{ files[0]?.name }}
                </h4>
                <div *ngIf="uploaded" xLayout="row" translate>
                  <img
                    src="../../../assets/ic_upload_complete.svg"
                    width="16px"
                    alt="file"
                  />
                  lbl-upload-complete
                </div>
                <app-progress
                  *ngIf="!uploaded"
                  [progress]="this.progress"
                ></app-progress>
              </div>
              <img
                src="../../../assets/ic_remove.svg"
                class="delete"
                width="20px"
                alt="file"
                (click)="deleteFile(0)"
              /><span (click)="deleteFile(0)" class="clickable">{{
                "lbl-remove" | translate | uppercase
              }}</span>
            </div>
          </div>

          <h4 translate>
            <span class="orange"></span>lbl-practice-information
          </h4>

          <mat-form-field>
            <mat-label>{{ "lbl-select-specialty" | translate }}*</mat-label>
            <mat-select
              *ngIf="practices"
              matSelect
              formControlName="speciality"
              multiple
            >
              <mat-option
                *ngFor="let speciality of specialities"
                [value]="speciality.ID"
              >
                {{ speciality.text | translate }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                providerForm.get('speciality')?.invalid &&
                providerForm.get('speciality')?.errors?.required
              "
              translate
              >error-select-specialty</mat-error
            >
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ "lbl-address" | translate }}*</mat-label>
            <input
              #addressAutoComplete
              [formControl]="providerForm.get('address')!"
              matInput
              matGoogleMapsAutocomplete
              (focus)="focusFunction($event)"
              (onAutocompleteSelected)="onAutocompleteSelected($event)"
              (onLocationSelected)="onLocationSelected($event)"
            />
            <span *ngIf="!hidePartialAddress && isEdit">{{
              this.currProvider?.practice?.location?.address
            }}</span>
            <mat-error
              *ngIf="
                providerForm.get('address')?.invalid &&
                providerForm.get('address')?.errors?.required
              "
              translate
              >error-required-address</mat-error
            >
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p translate>lbl-enter-lat-lng</p>
            <app-slider
              checkboxName="latLng"
              (isChecked)="checkLatLng($event)"
            ></app-slider>
          </div>
          <div
            *ngIf="showLatLngFormInputs"
            class="two-elements-row"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <mat-form-field floatLabel="always" fxFlex="48%">
              <mat-label translate>lbl-lat-col</mat-label>
              <input
                matInput
                type="number"
                formControlName="latitude"
                class="example-right-align"
              />
            </mat-form-field>
            <mat-form-field floatLabel="always" fxFlex="48%">
              <mat-label translate>lbl-lng-col</mat-label>
              <input
                matInput
                type="number"
                formControlName="longitude"
                class="example-right-align"
              />
            </mat-form-field>
          </div>
          <mat-form-field>
            <mat-label translate
              >{{ "lbl-phone-number" | translate }}*</mat-label
            >
            <input
              oninput="this.value=this.value.replace(/[^0-9-+]/g, '')"
              type="text"
              formControlName="tel_number"
              matInput
            />
            <mat-error
              *ngIf="
                providerForm.get('tel_number')?.invalid &&
                providerForm.get('tel_number')?.errors?.required
              "
              translate
              >error-required-phone</mat-error
            >
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label translate>lbl-email-address</mat-label>
            <input
              formControlName="email"
              type="email"
              matInput
              placeholder="Email"
            />
            <mat-error *ngIf="false" translate> error-valid-email</mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label translate>lbl-website</mat-label>
            <input formControlName="web_site" matInput />
            <mat-error
              *ngIf="
                providerForm.get('web_site')?.invalid &&
                providerForm.get('web_site')?.errors?.required
              "
              translate
              >error-required-site</mat-error
            >
          </mat-form-field>

          <p translate>lbl-price-range</p>
          <div class="form-fields">
            <div
              class="two-elements-row"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <mat-form-field floatLabel="always" fxFlex="48%">
                <mat-label translate>lbl-from</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="price_from"
                  class="example-right-align"
                  placeholder="0"
                />
                <span matPrefix>€&nbsp;</span>
              </mat-form-field>
              <mat-form-field floatLabel="always" fxFlex="48%">
                <mat-label translate>lbl-to</mat-label>
                <input
                  matInput
                  type="number"
                  formControlName="price_to"
                  class="example-right-align"
                  placeholder="0"
                />
                <span matPrefix>€&nbsp;</span>
              </mat-form-field>
            </div>
            <mat-error
              *ngIf="
                providerForm.get('price_to')?.invalid &&
                providerForm.get('price_to')?.errors?.lessThan
              "
              translate
              >error-price-to</mat-error
            >
          </div>

          <p translate>lbl-specify-hours</p>
          <div formArrayName="working_hours">
            <p class="day-p" translate>lbl-monday</p>
            <div
              class="timeslot-container"
              *ngFor="let numOfSlots of workingHours?.controls; let i = index"
            >
              <div
                *ngIf="
                  numOfSlots.value.weekday ==
                  translateService.instant('lbl-monday')
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
                [formGroupName]="i"
              >
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-from</mat-label>
                  <mat-select name="time" formControlName="from_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-to</mat-label>
                  <mat-select name="time" formControlName="to_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <img
                *ngIf="
                  i != 0 &&
                  numOfSlots.value.weekday ==
                    translateService.instant('lbl-monday')
                "
                class="remove-timeslot-icon"
                src="../../../assets/ic_remove.svg"
                (click)="
                  removeTimeslot(
                    i,
                    numOfSlots,
                    translateService.instant('lbl-monday')
                  )
                "
                alt=""
              />
            </div>

            <p
              class="clickable uppercase"
              (click)="addTimeSlot(translateService.instant('lbl-monday'))"
              translate
            >
              lbl-add-time-slot
            </p>

            <p class="day-p" translate>lbl-tuesday</p>
            <div
              class="timeslot-container"
              *ngFor="let numOfSlots of workingHours?.controls; let i = index"
            >
              <div
                *ngIf="
                  numOfSlots.value.weekday ==
                  translateService.instant('lbl-tuesday')
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
                [formGroupName]="i"
              >
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-from</mat-label>
                  <mat-select name="time" formControlName="from_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-to</mat-label>
                  <mat-select name="time" formControlName="to_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <img
                *ngIf="
                  i != 1 &&
                  numOfSlots.value.weekday ==
                    translateService.instant('lbl-tuesday')
                "
                class="remove-timeslot-icon"
                src="../../../assets/ic_remove.svg"
                (click)="
                  removeTimeslot(
                    i,
                    numOfSlots,
                    translateService.instant('lbl-tuesday')
                  )
                "
                alt=""
              />
            </div>

            <p
              class="clickable uppercase"
              (click)="addTimeSlot(translateService.instant('lbl-tuesday'))"
              translate
            >
              lbl-add-time-slot
            </p>

            <p class="day-p" translate>lbl-wednesday</p>
            <div
              class="timeslot-container"
              *ngFor="let numOfSlots of workingHours?.controls; let i = index"
            >
              <div
                fxLayout="row"
                *ngIf="
                  numOfSlots.value.weekday ==
                  translateService.instant('lbl-wednesday')
                "
                fxLayoutAlign="space-between center"
                [formGroupName]="i"
              >
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-from</mat-label>
                  <mat-select name="time" formControlName="from_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-to</mat-label>
                  <mat-select name="time" formControlName="to_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <img
                *ngIf="
                  i != 2 &&
                  numOfSlots.value.weekday ==
                    translateService.instant('lbl-wednesday')
                "
                class="remove-timeslot-icon"
                src="../../../assets/ic_remove.svg"
                (click)="
                  removeTimeslot(
                    i,
                    numOfSlots,
                    translateService.instant('lbl-wednesday')
                  )
                "
                alt=""
              />
            </div>
            <p
              class="clickable uppercase"
              (click)="addTimeSlot(translateService.instant('lbl-wednesday'))"
              translate
            >
              lbl-add-time-slot
            </p>

            <p class="day-p" translate>lbl-thursday</p>
            <div
              class="timeslot-container"
              *ngFor="let numOfSlots of workingHours?.controls; let i = index"
            >
              <div
                *ngIf="
                  numOfSlots.value.weekday ==
                  translateService.instant('lbl-thursday')
                "
                fxLayout="row"
                fxLayoutAlign="space-between center"
                [formGroupName]="i"
              >
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-from</mat-label>
                  <mat-select name="time" formControlName="from_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-to</mat-label>
                  <mat-select name="time" formControlName="to_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <img
                *ngIf="
                  i != 3 &&
                  numOfSlots.value.weekday ==
                    translateService.instant('lbl-thursday')
                "
                class="remove-timeslot-icon"
                src="../../../assets/ic_remove.svg"
                (click)="
                  removeTimeslot(
                    i,
                    numOfSlots,
                    translateService.instant('lbl-thursday')
                  )
                "
                alt=""
              />
            </div>
            <p
              class="clickable uppercase"
              (click)="addTimeSlot('Thursday')"
              translate
            >
              lbl-add-time-slot
            </p>

            <p class="day-p" translate>lbl-friday</p>
            <div
              class="timeslot-container"
              *ngFor="let numOfSlots of workingHours?.controls; let i = index"
            >
              <div
                fxLayout="row"
                *ngIf="
                  numOfSlots.value.weekday ==
                  translateService.instant('lbl-friday')
                "
                fxLayoutAlign="space-between center"
                [formGroupName]="i"
              >
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-from</mat-label>
                  <mat-select name="time" formControlName="from_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="48%">
                  <mat-label translate>lbl-to</mat-label>
                  <mat-select name="time" formControlName="to_hour">
                    <mat-option *ngFor="let time of times" [value]="time">
                      {{ time }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <img
                *ngIf="
                  i != 4 &&
                  numOfSlots.value.weekday ==
                    translateService.instant('lbl-friday')
                "
                class="remove-timeslot-icon"
                src="../../../assets/ic_remove.svg"
                (click)="
                  removeTimeslot(
                    i,
                    numOfSlots,
                    translateService.instant('lbl-friday')
                  )
                "
                alt=""
              />
            </div>
            <p
              class="clickable uppercase"
              (click)="addTimeSlot(translateService.instant('lbl-friday'))"
              translate
            >
              lbl-add-time-slot
            </p>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <p translate>lbl-enable-weekends</p>
              <app-slider
                checkboxName="weekends"
                (isChecked)="checkWeekends($event)"
              ></app-slider>
            </div>
            <div *ngIf="weekendsEnabled">
              <p class="day-p" translate>lbl-saturday</p>
              <div
                class="timeslot-container"
                *ngFor="let numOfSlots of workingHours?.controls; let i = index"
              >
                <div
                  fxLayout="row"
                  *ngIf="
                    numOfSlots.value.weekday ==
                    translateService.instant('lbl-saturday')
                  "
                  fxLayoutAlign="space-between center"
                  [formGroupName]="i"
                >
                  <mat-form-field fxFlex="48%">
                    <mat-label translate>lbl-from</mat-label>
                    <mat-select name="time" formControlName="from_hour">
                      <mat-option *ngFor="let time of times" [value]="time">
                        {{ time }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex="48%">
                    <mat-label translate>lbl-to</mat-label>
                    <mat-select name="time" formControlName="to_hour">
                      <mat-option *ngFor="let time of times" [value]="time">
                        {{ time }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <img
                  *ngIf="
                    i != 5 &&
                    numOfSlots.value.weekday ==
                      translateService.instant('lbl-saturday')
                  "
                  class="remove-timeslot-icon"
                  src="../../../assets/ic_remove.svg"
                  (click)="
                    removeTimeslot(
                      i,
                      numOfSlots,
                      translateService.instant('lbl-saturday')
                    )
                  "
                  alt=""
                />
              </div>
              <p
                class="clickable uppercase"
                (click)="addTimeSlot(translateService.instant('lbl-saturday'))"
                translate
              >
                lbl-add-time-slot
              </p>

              <p class="day-p" translate></p>
              <div
                class="timeslot-container"
                *ngFor="let numOfSlots of workingHours?.controls; let i = index"
              >
                <div
                  fxLayout="row"
                  *ngIf="
                    numOfSlots.value.weekday ==
                    translateService.instant('lbl-sunday')
                  "
                  fxLayoutAlign="space-between center"
                  [formGroupName]="i"
                >
                  <mat-form-field fxFlex="48%">
                    <mat-label translate>lbl-from</mat-label>
                    <mat-select name="time" formControlName="from_hour">
                      <mat-option *ngFor="let time of times" [value]="time">
                        {{ time }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field fxFlex="48%">
                    <mat-label translate>lbl-to</mat-label>
                    <mat-select name="time" formControlName="to_hour">
                      <mat-option *ngFor="let time of times" [value]="time">
                        {{ time }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <img
                  *ngIf="
                    i != 6 &&
                    numOfSlots.value.weekday ==
                      translateService.instant('lbl-sunday')
                  "
                  class="remove-timeslot-icon"
                  src="../../../assets/ic_remove.svg"
                  (click)="
                    removeTimeslot(
                      i,
                      numOfSlots,
                      translateService.instant('lbl-sunday')
                    )
                  "
                  alt=""
                />
              </div>
              <p
                class="clickable uppercase"
                (click)="addTimeSlot(translateService.instant('lbl-sunday'))"
                translate
              >
                lbl-add-time-slot
              </p>
            </div>
          </div>
          <h4 translate>
            <span class="orange"></span>lbl-additional-information
          </h4>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>{{ "lbl-is-eugen-recommended" | translate }}*</p>
            <app-slider
              [form]="providerForm"
              checkboxName="is_eugen_recommended"
              nameOfControl="is_eugen_recommended"
              (isChecked)="checkRecommended($event)"
            ></app-slider>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <p>{{ "lbl-is-moneytrack-supported" | translate }}*</p>
            <app-slider
              [form]="providerForm"
              nameOfControl="is_moneytrack_supported"
              checkboxName="is_moneytrack_supported"
              (isChecked)="checkMoneyTrack($event)"
            ></app-slider>
          </div>
          <mat-form-field>
            <mat-label translate>lbl-additional-notes</mat-label>
            <textarea matInput formControlName="additional_note"></textarea>
          </mat-form-field>
        </form>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasSaveProvider]="true"
    [hasCancel]="true"
    (addProvider)="addNewProvider()"
    (goBack)="goBack()"
    fxFlex="16"
    fxFlex.md="10"
  ></app-sidebar-buttons>
</div>
