import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Language } from "@app/@shared/interfaces/language.interface";
import { Practice } from "@app/@shared/interfaces/practice.interface";
import { Provider } from "@app/@shared/interfaces/provider.interface";
import { Speciality } from "@app/@shared/interfaces/speciality.interface";
import { Logger } from "@app/@shared/logger.service";
import { LoaderService } from "@app/@shared/services/loader.service";
import { ModelsService } from "@app/@shared/services/models.service";
import { I18nService } from "@app/i18n";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { HomeService } from "../home.service";

const log = new Logger("AddNewProviderComponent");

@Component({
  selector: "app-add-new-provider",
  templateUrl: "./add-new-provider.component.html",
  styleUrls: ["./add-new-provider.component.scss"],
})
export class AddNewProviderComponent implements OnInit {
  providerForm!: FormGroup;
  timeSlots: any = {};

  times: string[] = [];
  workingHours: FormArray | undefined;
  @ViewChild("fileDropRef", { static: false }) fileDropEl:
    | ElementRef
    | undefined;
  files: File[] = [];
  progress: number = 0;
  uploaded = false;

  practices: Practice[] = [];
  languages: Language[] = [];
  specialities: Speciality[] = [];

  showLatLngFormInputs?: boolean = false;
  eugenRecommended: boolean = false;
  weekendsEnabled: boolean = false;
  moneyTrack: boolean = false;
  isEdit: boolean = false;

  currProvider: Provider | undefined;
  urlId: string | null = "";
  hidePartialAddress: boolean = false;
  currentPractice: number = 0;
  appLanguage: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private modelsService: ModelsService,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private i18nService: I18nService,
    private loaderService: LoaderService,
    public translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this.appLanguage = this.i18nService.language;

    this.timeSlots[this.translateService.instant("lbl-monday")] = [];
    this.timeSlots[this.translateService.instant("lbl-tuesday")] = [];
    this.timeSlots[this.translateService.instant("lbl-wednesday")] = [];
    this.timeSlots[this.translateService.instant("lbl-thursday")] = [];
    this.timeSlots[this.translateService.instant("lbl-friday")] = [];
    this.timeSlots[this.translateService.instant("lbl-saturday")] = [];
    this.timeSlots[this.translateService.instant("lbl-sunday")] = [];

    if (this.route.snapshot.paramMap.get("id")) {
      this.urlId = this.route.snapshot.paramMap.get("id");
      this.isEdit = true;
    }

    if (this.router.getCurrentNavigation()?.extras.state) {
      this.currProvider = this.router.getCurrentNavigation()?.extras
        .state as Provider;
    } else {
      if (this.isEdit) {
        this.getCurrentMedicalProvider(this.urlId);
      }
    }

    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });

    this.generateTimeTable();
    this.createForm();
    this.getMedicalProviderData();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  /**
   * Create Form Function
   */
  createForm() {
    if (this.currProvider) {
      let languages_spoken: number[] = [];
      for (let i of this.currProvider.languages_spoken) {
        languages_spoken.push(i.ID);
      }

      let specialities: number[] = [];
      for (let i of this.currProvider.practice.medical_speciality) {
        specialities.push(i.ID);
      }

      this.providerForm = this.formBuilder.group({
        type_of_practice: [
          this.currProvider.type_of_practice.ID || "",
          Validators.required,
        ],
        first_name: [this.currProvider.first_name || "", Validators.required],
        img: [""],
        last_name: [this.currProvider.last_name || "", Validators.required],
        clinic_name: [this.currProvider.clinic_name || "", Validators.required],
        additional_clinics: [this.currProvider.additional_clinics],
        languages: [languages_spoken],
        address: [
          this.currProvider.practice.location.address || "",
          Validators.required,
        ],
        longitude: [
          this.currProvider.practice.location.longitude || "",
          Validators.required,
        ],
        latitude: [
          this.currProvider.practice.location.latitude || "",
          Validators.required,
        ],
        tel_number: [
          this.currProvider.practice.contact.tel_number || "",
          Validators.required,
        ],
        email: [this.currProvider.practice.contact.email || ""],
        web_site: [this.currProvider.practice.contact.web_site],
        price_from: [this.currProvider.practice.price_from || null],
        price_to: [this.currProvider.practice.price_to || null],
        working_hours: this.formBuilder.array([]),
        is_eugen_recommended: [
          this.currProvider.practice.is_eugen_recommended || false,
        ],
        is_moneytrack_supported: [
          this.currProvider.practice.is_moneytrack_supported || false,
        ],
        additional_note: [""],
        speciality: [specialities, Validators.required],
      });

      this.providerForm
        .get("address")!
        .setValue(this.currProvider.practice.location.address);

      this.checkRecommended(this.currProvider.practice.is_eugen_recommended);
      this.workingHours = <FormArray>this.providerForm.get("working_hours");
      this.fillHours(this.currProvider.practice.working_hours);

      this.practiceChanged(this.currProvider.type_of_practice.ID);
    } else {
      this.providerForm = this.formBuilder.group({
        type_of_practice: ["", Validators.required],
        first_name: ["", Validators.required],
        img: [""],
        last_name: ["", Validators.required],
        clinic_name: ["", Validators.required],
        additional_clinics: [""],
        languages: [""],
        address: ["", Validators.required],
        longitude: ["", Validators.required],
        latitude: ["", Validators.required],
        tel_number: ["", Validators.required],
        email: [""],
        web_site: [""],
        price_from: [null],
        price_to: [null],
        working_hours: this.formBuilder.array([]),
        is_eugen_recommended: [false, Validators.required],
        from_date: [""],
        to_date: [""],
        is_moneytrack_supported: [false, Validators.required],
        additional_note: [""],
        speciality: ["", Validators.required],
      });
      this.workingHours = <FormArray>this.providerForm.get("working_hours");
      this.addTimeSlot("lbl-monday");
      this.addTimeSlot("lbl-tuesday");
      this.addTimeSlot("lbl-wednesday");
      this.addTimeSlot("lbl-thursday");
      this.addTimeSlot("lbl-friday");
      this.addTimeSlot("lbl-saturday");
      this.addTimeSlot("lbl-sunday");
    }
  }

  practiceChanged(value: any) {
    this.currentPractice = value;
    if (this.currentPractice == 2) {
      this.providerForm.get("first_name")!.disable();
      this.providerForm.get("last_name")!.disable();
      this.providerForm.get("clinic_name")!.enable();
      this.providerForm.get("first_name")!.setValidators(null);
      this.providerForm.get("first_name")!.updateValueAndValidity();
      this.providerForm.get("last_name")!.setValidators(null);
      this.providerForm.get("last_name")!.updateValueAndValidity();
      this.providerForm
        .get("clinic_name")!
        .setValidators([Validators.required]);
      this.providerForm.get("clinic_name")!.updateValueAndValidity();
      this.providerForm.get("first_name")!.setValue(null);
      this.providerForm.get("last_name")!.setValue(null);
    } else {
      this.providerForm.get("clinic_name")!.disable();
      this.providerForm.get("first_name")!.enable();
      this.providerForm.get("last_name")!.enable();
      this.providerForm.get("first_name")!.setValidators([Validators.required]);
      this.providerForm.get("first_name")!.updateValueAndValidity();
      this.providerForm.get("last_name")!.setValidators([Validators.required]);
      this.providerForm.get("last_name")!.updateValueAndValidity();
      this.providerForm.get("clinic_name")!.setValidators(null);
      this.providerForm.get("clinic_name")!.updateValueAndValidity();
      this.providerForm.get("clinic_name")!.setValue(null);
    }
  }

  checkMoneyTrack(value: any) {
    this.moneyTrack = value;
  }

  checkRecommended(value: any) {
    this.eugenRecommended = value;
  }

  checkWeekends(value: any) {
    this.weekendsEnabled = value;
  }

  checkLatLng(value: boolean) {
    this.showLatLngFormInputs = value;
  }

  fillHours(working_hours: any[]) {
    for (let working_hour of working_hours) {
      this.addTimeSlot(working_hour.weekday, {
        from_hour: working_hour.from_hour,
        to_hour: working_hour.to_hour,
      });
    }
  }

  /**
   * add timeslot for working days
   */
  addTimeSlot(day: string, timeObject?: any) {
    day = this.translateService.instant(day);
    this.timeSlots[day].push(this.timeSlots[day].length);
    if (timeObject && timeObject.from_hour && timeObject.to_hour) {
      this.workingHours!.push(
        this.formBuilder.group({
          weekday: [day],
          from_hour: [timeObject.from_hour],
          to_hour: [timeObject.to_hour],
        })
      );
    } else {
      this.workingHours!.push(
        this.formBuilder.group({
          weekday: [day],
          from_hour: [""],
          to_hour: [""],
        })
      );
    }
  }

  /**
   * remove timeslots
   */
  removeTimeslot(i: number, timeslot: any, day: string) {
    let workingHours = <FormArray>this.providerForm.get("working_hours");
    workingHours.removeAt(i);
    this.timeSlots[day].pop();
  }

  /**
   * on file drop handler
   */
  onFileDropped($event: any) {
    this.prepareFilesList($event);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(target: any) {
    let files = [];
    if (target.files) {
      files = target.files;
    }

    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.progress < 100) {
      return;
    }

    this.files.splice(index, 1);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      const progressInterval = setInterval(() => {
        if (this.progress === 100) {
          this.uploaded = true;
          clearInterval(progressInterval);
          this.uploadFilesSimulator(0);
        } else {
          this.progress += 5;
        }
      }, 200);
    }, 1000);
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: File[]) {
    this.files[0] = files[0];
    this.progress = 0;

    if (this.fileDropEl) {
      this.fileDropEl.nativeElement.value = "";
      this.uploaded = false;
      this.uploadFilesSimulator(0);
    }
  }

  getMedicalProviderData() {
    let requests = [];
    requests.push(this.homeService.getMedicalProvidersLanguages());
    requests.push(this.homeService.getMedicalProvidersPractices());
    requests.push(this.homeService.getMedicalProvidersSpecialities());

    this.loaderService.shouldRunLoader.next(true);
    forkJoin(requests).subscribe(
      (responses) => {
        this.languages = responses[0];
        this.practices = responses[1];
        this.specialities = responses[2];
        this.modelsService.languages.next(responses[0]);
        this.modelsService.practices.next(responses[1]);
        this.modelsService.specialities.next(responses[2]);
        this.loaderService.shouldRunLoader.next(false);
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);
      }
    );
  }

  private getCurrentMedicalProvider(id: string | null) {
    if (id) {
      this.loaderService.shouldRunLoader.next(true);
      return this.homeService.getMedicalProviderById(+id).subscribe(
        (response) => {
          this.loaderService.shouldRunLoader.next(false);
          this.currProvider = response;
          this.createForm();
        },
        (error) => {
          this.loaderService.shouldRunLoader.next(false);
        }
      );
    } else {
      return this.router.navigate(["/list-providers"]);
    }
  }

  private generateTimeTable() {
    this.times = this.getTimeRanges(30, "ru");
  }

  private getTimeRanges(interval: any, language = window.navigator.language) {
    const ranges = [];
    const date = new Date();

    for (let minutes = 0; minutes < 24 * 60; minutes = minutes + interval) {
      date.setHours(0);
      date.setMinutes(minutes);
      ranges.push(
        date.toLocaleTimeString(language, {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }
    
    let rangesBestHours = [...ranges.splice(14), ...ranges.splice(0, 14)];

    return rangesBestHours;
  }

  /**
   * Submits form
   */
  addNewProvider(): void {
    if (this.providerForm.invalid) {
      return;
    }

    let type_of_practice = {
      ID: this.providerForm.value.type_of_practice,
    };

    let working_hours = [...this.providerForm.value.working_hours];
    for (let timeEntry of working_hours) {
      switch (timeEntry.weekday) {
        case "Lundi":
          timeEntry.weekday = "Monday";
          break;
        case "Mardi":
          timeEntry.weekday = "Tuesday";
          break;
        case "Mercredi":
          timeEntry.weekday = "Wednesday";
          break;
        case "Jeudi":
          timeEntry.weekday = "Thursday";
          break;
        case "Vendredi":
          timeEntry.weekday = "Friday";
          break;
        case "Samedi":
          timeEntry.weekday = "Saturday";
          break;
        case "Dimanche":
          timeEntry.weekday = "Sunday";
          break;
        default:
          break;
      }
    }

    let location = {
      address: this.providerForm.value.address,
      latitude: this.providerForm.value.latitude,
      longitude: this.providerForm.value.longitude,
    };

    let contact = {
      tel_number: this.providerForm.value.tel_number,
      email: this.providerForm.value.email,
      web_site: this.providerForm.value.web_site,
    };

    let languages_spoken = [] as any;
    for (let i of this.providerForm.value.languages) {
      let obj = {
        ID: i,
      };
      languages_spoken.push(obj);
    }

    let specialities = [] as any;
    for (let i of this.providerForm.value.speciality) {
      let obj = {
        ID: i,
      };
      specialities.push(obj);
    }

    let data = {
      type_of_practice,
      first_name: this.providerForm.value.first_name || "",
      last_name: this.providerForm.value.last_name || "",
      additional_clinics: this.providerForm.value.additional_clinics,
      clinic_name: this.providerForm.value.clinic_name || "",
      languages_spoken,
      practice: {
        location,
        is_eugen_recommended: this.providerForm.value.is_eugen_recommended,
        contact,
        medical_speciality: specialities,
        working_hours,
        price_from: this.providerForm.value.price_from,
        price_to: this.providerForm.value.price_to,
        is_moneytrack_supported: this.providerForm.value.is_moneytrack_supported,
        additional_note: this.providerForm.value.additional_note,
      },
    };

    this.loaderService.shouldRunLoader.next(true);
    if (this.isEdit) {
      this.homeService.updateProvider(this.urlId!, data).subscribe(
        (res) => {
          log.debug("updateProvider response", res);
          let imageData = new FormData();
          imageData.append("img", this.files[0]);

          this._snackBar.open(
            this.translateService.instant("lbl-provider-created"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );

          if (imageData) {
            this.homeService.postImageForProvider(res.id, imageData).subscribe(
              (res) => {
                this.loaderService.shouldRunLoader.next(false);
                log.debug("postImageForProvider response", res);
              },
              (error) => {
                this.loaderService.shouldRunLoader.next(false);
                log.error("updateProvider error", error);
              }
            );
          } else {
            this.loaderService.shouldRunLoader.next(false);
          }

          this.router.navigate(["/provider", this.urlId]);
        },
        (error) => {
          this._snackBar.open(
            this.translateService.instant("lbl-something-wrong"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["error-snackbar"],
            }
          );

          this.loaderService.shouldRunLoader.next(false);
          log.error("postNewProvider error", error);
        }
      );
    } else {
      this.homeService.postNewProvider(data).subscribe(
        (res) => {
          log.debug("postNewProvider response", res);
          let imageData = new FormData();
          imageData.append("img", this.files[0]);

          this._snackBar.open(
            this.translateService.instant("lbl-provider-created"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );

          this.router.navigate(["/list-providers"]);

          this.homeService.postImageForProvider(res.id, imageData).subscribe(
            (res) => {
              this.loaderService.shouldRunLoader.next(false);
              log.debug("postNewProvider response", res);
            },
            (error) => {
              this.loaderService.shouldRunLoader.next(false);
              log.error("postNewProvider error", error);
            }
          );
        },
        (error) => {
          this._snackBar.open(
            this.translateService.instant("lbl-something-wrong"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["error-snackbar"],
            }
          );
          this.loaderService.shouldRunLoader.next(false);
          log.error("postNewProvider error", error);
        }
      );
    }
  }

  greaterThan(field: string): ValidatorFn {
    return (control: AbstractControl) => {
      const group = control.parent;
      const fieldToCompare = group?.get(field);
      const isLessThan = Number(fieldToCompare?.value) > Number(control.value);
      return isLessThan ? { lessThan: { value: control.value } } : null;
    };
  }

  onAutocompleteSelected(result: any) {
    log.debug("onAutocompleteSelected: ", result);
  }

  onLocationSelected(location: any) {
    log.debug("onLocationSelected: ", location);
    this.providerForm
      .get("latitude")
      ?.patchValue(JSON.stringify(location.latitude));
    this.providerForm
      .get("longitude")
      ?.patchValue(JSON.stringify(location.longitude));
  }

  focusFunction($event: any) {
    if (this.isEdit) {
      $event.srcElement.value = this.currProvider?.practice?.location.address;
      this.hidePartialAddress = true;
    }
  }

  goBack() {
    if (this.isEdit) {
      this.router.navigate(["/provider", this.urlId]);
    } else {
      this.router.navigate(["/list-providers"]);
    }
  }
}
