<div class="dialog-container">
  <form
    fxFlex="100%"
    fxLayout="column"
    fxLayoutAlign="space-around normal"
    [formGroup]="loginForm"
    novalidate
  >
    <h1 mat-dialog-title translate>lbl-reset-password</h1>
    <div mat-dialog-content fxLayout="column" fxFlex="50%">
      <p translate>
        lbl-reset-password-process
      </p>
      <mat-form-field [hideRequiredMarker]="true">
        <input
          type="text"
          matInput
          formControlName="username"
          autocomplete="username"
          [placeholder]="'Your Username' | translate"
          required
        />
        <mat-error
          *ngIf="
            loginForm.controls.username.invalid &&
            loginForm.controls.username.errors!.required &&
            loginForm.controls.username.touched
          "
        >
          <span translate>lbl-username-required</span>
        </mat-error>
        <mat-error
        *ngIf="
          loginForm.controls.username.invalid &&
          loginForm.controls.username.errors!.email && 
          loginForm.controls.username.touched
        "
      >
        <span translate>lbl-username-incorrect</span>
      </mat-error>
      </mat-form-field>
    </div>
    <div align="center">
      <div class="auth-button-border dialog" fxLayoutAlign="center center">
        <button
          mat-button
          color="primary"
          (click)="login()"
          id="auth-button"
          type="submit"
          [disabled]="loginForm.invalid || isLoading"
        >
          <app-loader
            class="inline-loader"
            [isLoading]="isLoading"
          ></app-loader>
          <span *ngIf="!isLoading" translate>lbl-continue</span>
        </button>
      </div>
      <div class="auth-button-border dialog" fxLayoutAlign="center center">
        <button mat-button mat-dialog-close color="primary" id="cancel-button">
          <span translate>lbl-cancel</span>
        </button>
      </div>
    </div>
  </form>
</div>
