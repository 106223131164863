import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Administrator } from "@app/@shared/interfaces/administrator.interface";
import { AuthenticationService } from "@app/auth/authentication.service";

@Component({
  selector: "app-navbar-details",
  templateUrl: "./navbar-details.component.html",
  styleUrls: ["./navbar-details.component.scss"],
})
export class NavbarDetailsComponent implements OnInit {
  @Input() currAdministrator: Administrator | undefined;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {}

  goToUserPage(index: number) {
    this.router.navigate(["/administrator", index]);
  }

  logout() {
    return this.authenticationService.logout().subscribe(() => {
      console.log("Logout");
    });
  }
}
