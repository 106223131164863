<div class="dialog-container" fxLayout="column">
  <img class="admin-icon" src="../../../assets/img_email.svg" />
  <h1 mat-dialog-title translate>lbl-sent-email</h1>
  <div mat-dialog-content fxLayout="column" fxFlex="50%">
    <p translate>
      lbl-please-check-inbox
    </p>
  </div>
  <div align="center">
    <div class="auth-button-border dialog" fxLayoutAlign="center center">
      <button
        mat-dialog-close
        mat-button
        color="primary"
        id="auth-button"
        type="submit"
      >
        <app-loader class="inline-loader"></app-loader>
        <span translate>lbl-ok</span>
      </button>
    </div>
  </div>
</div>
