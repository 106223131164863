import { Component, OnInit, ViewChild } from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";

import { UntilDestroy } from "@shared";
import { NavService } from "@app/@shared/services/nav.service";
import {
  MatDrawerMode,
  MatSidenav,
  MatSidenavContent,
} from "@angular/material/sidenav";
import { NavItem } from "@app/@shared/interfaces/nav-item.interface";
import { RouterEvent } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { LoaderService } from "@app/@shared/services/loader.service";
import { ModelsService } from "@app/@shared/services/models.service";

@UntilDestroy()
@Component({
  selector: "app-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.scss"],
})
export class ShellComponent implements OnInit {
  @ViewChild("appDrawer") appDrawer?: MatSidenav;
  mode: MatDrawerMode = "side";
  @ViewChild("sidenavContent", { read: MatSidenavContent })
  sidenavContentScrollable?: MatSidenavContent;

  navItems: NavItem[] = [
    {
      displayName: "Home",
      iconName: "ic_home_active.svg",
      route: "/home",
      expanded: false,
    },
    {
      displayName: "User Administration",
      iconName: "ic_app_settings.svg",
      route: "",
      expanded: false,
      children: [
        {
          displayName: "List of Plaform Users",
          iconName: "",
          route: "/list-users",
        },
        {
          displayName: "List Of Administrators",
          iconName: "",
          route: "/list-administrators",
        },
        {
          displayName: "Add New Administrator",
          iconName: "",
          route: "/add-new-administrator",
        },
      ],
    },
    {
      displayName: "Medical Providers",
      iconName: "ic_medical_assistance.svg",
      route: "",
      expanded: false,
      children: [
        {
          displayName: "List of Medical Providers",
          iconName: "",
          route: "/list-providers",
        },
        {
          displayName: "Add new Provider",
          iconName: "",
          route: "/add-new-provider",
        },
      ],
    },
    {
      displayName: "Legal",
      iconName: "ic_legal_info.svg",
      route: "",
      expanded: false,
      children: [
        {
          displayName: "Terms of Use",
          iconName: "",
          route: "/terms-of-use",
        },
        {
          displayName: "Privacy Policy",
          iconName: "",
          route: "/privacy-policy",
        },
      ],
    },
    {
      displayName: "About Eugen",
      iconName: "ic_about_app.svg",
      expanded: false,
      route: "/about",
    },
  ];

  constructor(
    private media: MediaObserver,
    private navService: NavService,
    private ngxLoader: NgxUiLoaderService,
    private loaderService: LoaderService,
    private modelService: ModelsService
  ) {
    this.loaderService.shouldRunLoader.subscribe((value) => {
      if (value) {
        this.ngxLoader.startLoader("shell-loader");
      } else {
        this.ngxLoader.stopLoader("shell-loader");
      }
    });
  }

  ngOnInit() {
    this.media.asObservable().subscribe((changes: MediaChange[]) => {
      changes.forEach((item) => {
        let activeQuery = item
          ? `'${item.mqAlias}' = (${item.mediaQuery})`
          : "";

        if (item.mqAlias === "sm" || item.mqAlias === "xs") {
          this.mode = "over";
          this.appDrawer?.close();
        } else if (item.mqAlias === "md" || item.mqAlias == "lg") {
          this.appDrawer?.open();
          this.mode = "side";
        }
      });
    });
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
    this.modelService.currLoggedInAdminSuperAdmin.subscribe((isSuperAdmin) => {
      if (!isSuperAdmin && isSuperAdmin != undefined) {
        this.navItems[1].children?.pop();
      }
    });
  }

  onExpand(event: any) {
    let sidebarName = event.displayName;
  }

  onActivate(event: RouterEvent) {
    if (this.sidenavContentScrollable) {
      this.sidenavContentScrollable.scrollTo({ top: 0 });
    }
  }
}
