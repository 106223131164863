import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { Logger } from "@app/@shared/logger.service";
import { LoaderService } from "@app/@shared/services/loader.service";
import { ModelsService } from "@app/@shared/services/models.service";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../home.service";

const log = new Logger("AdministratorComponent");
@Component({
  selector: "app-administrator",
  templateUrl: "./administrator.component.html",
  styleUrls: ["./administrator.component.scss"],
})
export class AdministratorComponent implements OnInit {
  routeId: string | null;
  currAdministrator: any;
  isLoggedInAdminSuperUser: boolean = false;

  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loaderService: LoaderService,
    private modelService: ModelsService,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this.routeId = this.route.snapshot.paramMap.get("id");
    if (this.routeId) {
      this.getAdministrator(this.routeId);
    } else {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit(): void {
    this.modelService.currLoggedInAdminSuperAdmin.subscribe((value) => {
      if (value != undefined) {
        this.isLoggedInAdminSuperUser = value || this.isAdministratorOnOwnScreen();
      }
    });
  }

  editInformation() {
    this.router.navigateByUrl(
      `edit-administrator/${this.currAdministrator.id}`,
      {
        state: this.currAdministrator,
      }
    );
  }

  resetPassword(email: string) {
    if (email) {
      this.loaderService.shouldRunLoader.next(true);

      this.homeService.requestNewPassword(email).subscribe(
        (response) => {
          this.loaderService.shouldRunLoader.next(false);
        },
        (error) => {
          this.loaderService.shouldRunLoader.next(false);
        }
      );
    }
  }

  suspendProfile(id: string, enable: boolean) {
    this.loaderService.shouldRunLoader.next(true);
    this.homeService.toggleEnabled(+id, enable).subscribe(
      (response) => {
        if (enable) {
          this._snackBar.open(
            this.translateService.instant("lbl-administrator-enabled"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );
        } else {
          this._snackBar.open(
            this.translateService.instant("lbl-administrator-suspended"),
            this.translateService.instant("lbl-cancel"),
            {
              panelClass: ["success-snackbar"],
            }
          );
        }
        this.loaderService.shouldRunLoader.next(false);
        log.debug("SuspendProfile", this.currAdministrator);
        if (this.routeId) {
          this.getAdministrator(this.routeId);
        }
      },
      (error) => {
        this._snackBar.open(
          this.translateService.instant("lbl-something-wrong"),
          this.translateService.instant("lbl-cancel"),
          {
            panelClass: ["error-snackbar"],
          }
        );
        this.loaderService.shouldRunLoader.next(false);

        log.error(error);
      }
    );
  }

  getAdministrator(id: string) {
    this.loaderService.shouldRunLoader.next(true);

    this.homeService.getAdminById(+id).subscribe(
      (response) => {
        this.currAdministrator = response;
        this.loaderService.shouldRunLoader.next(false);
        log.debug("Current Administrator", this.currAdministrator);
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);

        log.error(error);
      }
    );
  }

  isAdministratorOnOwnScreen() {
    return this.modelService.currUserId.value == +this.routeId!;
  }
}
