<div
  *ngIf="loaded"
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex="86">
    <div class="container main-menu-element-container">
      <div class="title-container">
        <div
          class="title-wrapper"
          fxLayout.gt-md="row"
          fxLayoutAlign.gt-md="space-between center"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <h2 *ngIf="!data.search" id="title" translate>lbl-providers-list</h2>
          <h2 *ngIf="data.search" id="title" translate>
            {{ "lbl-search-result" | translate }} '{{ data.search }}'
          </h2>

          <form class="providers-form" novalidate>
            <mat-form-field>
              <mat-label translate>lbl-sort-by</mat-label>
              <mat-select
                matSelect
                [(value)]="sortBy"
                (selectionChange)="sortTableBy($event)"
              >
                <mat-option value="date_added">{{
                  "lbl-date-added" | translate
                }}</mat-option>
                <mat-option value="first_name">{{
                  "lbl-name" | translate
                }}</mat-option>
                <mat-option value="clinic_name">{{
                  "lbl-name-of-practice" | translate
                }}</mat-option>
                <mat-option value="type_of_practice">{{
                  "lbl-type-of-practice" | translate
                }}</mat-option>
                <mat-option *ngIf="appLanguage == 'en-US'" value="practice__medical_speciality__text_en">{{
                  "lbl-specialty" | translate
                }}</mat-option>
                <mat-option *ngIf="appLanguage == 'fr-FR'" value="practice__medical_speciality__text_fr">{{
                  "lbl-specialty" | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label translate>lbl-show</mat-label>
              <mat-select
                matSelect
                [(value)]="data.limit"
                (selectionChange)="showTableBy($event)"
              >
                <mat-option value="10">10</mat-option>
                <mat-option value="20">20</mat-option>
                <mat-option value="50">50</mat-option>
                <mat-option value="100">100</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
        <div class="additional-filters">
          <form novalidate>
              <div class="additional-filters-input">
                  <input
                          [placeholder]="'lbl-address' | translate"
                          placeholder="Address/City"
                          (keydown)="viewSearch($event)"
                          [formControl]="searchEntityCtrl"
                  />
                  <mat-icon matSuffix [ngStyle]="{ color: '#8C99A7' }">search</mat-icon>
              </div>

              <mat-form-field>
                <mat-label translate>lbl-select-type-of-practice</mat-label>
                <mat-select
                        (selectionChange)="handlePracticeChange($event)">
                  <mat-option [value]="">-</mat-option>
                  <mat-option  *ngFor="let practice of practices"
                               [value]="practice.ID">{{practice.text}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field>
                <mat-label translate>lbl-select-specialty</mat-label>
                <mat-select
                        (selectionChange)="handleSpecialityChange($event)">
                  <mat-option [value]="">-</mat-option>
                  <mat-option  *ngFor="let speciality of specialities"
                               [value]="speciality.ID">{{speciality['text_' + selectLang]}}</mat-option>
                </mat-select>
              </mat-form-field>
          </form>
          <button
                  (click)="exportMedicalProviders()"
                  mat-button
                  color="primary"
                  class="export-button"
          >
            <span translate>lbl-export</span>
          </button>
        </div>
      </div>
      <mat-card>
        <mat-table
          [dataSource]="
            medicalProviders
              | paginate
                : {
                    itemsPerPage: data.limit,
                    currentPage: data.page,
                    totalItems: totalItems
                  }
          "
        >
          <ng-container matColumnDef="icon">
            <mat-header-cell fxFlex="90px" *matHeaderCellDef></mat-header-cell>
            <mat-cell fxFlex="90px" *matCellDef="let element"
              ><div class="cell-name-wrapper">
                <img *ngIf="element.img" [src]="element.img" alt="" />
                <ngx-avatar
                  *ngIf="
                    !element.img && element.type_of_practice.text != 'Clinic'
                  "
                  name="{{ element.name }}"
                  initialsSize="4"
                ></ngx-avatar>
                <ngx-avatar
                  *ngIf="
                    !element.img && element.type_of_practice.text == 'Clinic'
                  "
                  name="{{ element.clinic_name }}"
                  initialsSize="4"
                ></ngx-avatar>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef translate
              >lbl-name</mat-header-cell
            >
            <mat-cell *matCellDef="let element"
              ><div class="cell-name-wrapper">
                <div class="cell-name-elements">
                  <span
                    class="ellipsis-one-line name"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{ element.name }}</span
                  >
                  <span
                    class="ellipsis-one-line sub-name"
                    (mouseenter)="mouseEnter($event)"
                    (mouseleave)="mouseLeave($event)"
                    >{{
                      (element &&
                        element.practice &&
                        element.practice.contact.email) ||
                        ""
                    }}</span
                  >
                </div>
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="nameOfPractice">
            <mat-header-cell *matHeaderCellDef translate
              >lbl-name-of-practice</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{ element.clinic_name }}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="typeOfPractice">
            <mat-header-cell *matHeaderCellDef translate
              >lbl-type-of-practice</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.type_of_practice && element.type_of_practice.text | translate
            }}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="address">
            <mat-header-cell *matHeaderCellDef translate
              >lbl-address</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              <span
                class="ellipsis"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{
                  (element.practice &&
                    element.practice.location &&
                    element.practice.location.address) ||
                    ""
                }}</span
              ></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="tel_number">
            <mat-header-cell fxFlex="12" *matHeaderCellDef translate
              >lbl-phone-number</mat-header-cell
            >
            <mat-cell fxFlex="12" *matCellDef="let element">
              <span
                class="ellipsis-one-line"
                (mouseenter)="mouseEnter($event)"
                (mouseleave)="mouseLeave($event)"
              >
                {{
                  (element.practice &&
                    element.practice.contact &&
                    element.practice.contact.tel_number) ||
                    ""
                }}</span
              >
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row
            (click)="goToIndividualScreen(row)"
            *matRowDef="let row; columns: displayedColumns"
          ></mat-row>
        </mat-table>
        <div class="pagination-wrapper" fxLayoutAlign="center center">
          <pagination-controls
            previousLabel=""
            nextLabel=""
            (pageChange)="onPageChange($event)"
          ></pagination-controls>
        </div>
      </mat-card>
    </div>
  </div>
  <app-sidebar-buttons
    fxFlex="30"
    fxFlex.gt-sm="14"
    [hasAddProviderRedirect]="true"
  ></app-sidebar-buttons>
  <ngx-ui-loader
    overlayColor="#f6f6f6"
    pbColor="#ff6900"
    fgsColor="#ff6900"
    [loaderId]="'homepage-loader'"
  ></ngx-ui-loader>
</div>
