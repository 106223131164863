import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { ModelsService } from "@app/@shared/services/models.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HomeService } from "../home.service";

@Component({
  selector: "app-list-administrators",
  templateUrl: "./list-administrators.component.html",
  styleUrls: ["./list-administrators.component.scss"],
})
export class ListAdministratorsComponent implements OnInit {
  totalItems: number = 0;

  data = {
    offset: 0,
    limit: "10",
    page: 1,
    search: "",
    sortBy: "",
  };

  @ViewChild(MatSort)
  sort!: MatSort;
  providersAdmin = [{}];

  displayedColumnsAdmin: string[] = [
    "icon",
    "name",
    "username",
    "address",
    "tel_number",
  ];

  constructor(
    private homeService: HomeService,
    private router: Router,
    private modelsService: ModelsService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.getAllAdmins();

    this.modelsService.searchData.subscribe((value) => {
      this.data.search = value;
      this.getAllAdmins();
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  getAllAdmins() {
    this.ngxLoader.startLoader("homepage-loader");
    this.homeService.getAllAdmins(this.data).subscribe(
      (response) => {
        this.totalItems = response.count;
        this.providersAdmin = response.results;
        this.ngxLoader.stopLoader("homepage-loader");
      },
      (error) => {
        this.ngxLoader.stopLoader("homepage-loader");
      }
    );
  }

  goToIndividualScreen(entity: any) {
    this.router.navigate(["/administrator", entity.id]);
  }

  sortTableBy(event: any) {
    let _sortBy = event.value;
    this.data.sortBy = _sortBy;
    this.getAllAdmins();
  }

  showTableBy(event: any) {
    let _showBy = event.value;
    this.data.limit = _showBy;
    if (this.totalItems < +this.data.limit * this.data.page) {
      this.data.page = Math.ceil(this.totalItems / +this.data.limit);
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllAdmins();
  }

  onPageChange(event: any) {
    this.providersAdmin = [];
    this.data.page = event;
    if (this.data.page == 0) {
      this.data.offset = 0;
    } else {
      this.data.offset = (this.data.page - 1) * +this.data.limit;
    }
    this.getAllAdmins();
  }

  mouseEnter(event: any) {
    let element = event.srcElement;
    if (element) {
      element.setAttribute("title", element.innerHTML);
    }
  }

  mouseLeave(event: any) {}
}
