import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AreYouSureComponent } from "@app/@shared/components/are-you-sure/are-you-sure.component";
import { DialogData } from "@app/@shared/components/types/dialog-data.interface";
import { Provider } from "@app/@shared/interfaces/provider.interface";
import { LoaderService } from "@app/@shared/services/loader.service";
import { ModelsService } from "@app/@shared/services/models.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";
import { HomeService } from "../home.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  routeId: string | null;
  medicalProvider: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private modelsService: ModelsService,
    private loaderService: LoaderService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private _snackBar: MatSnackBar
  ) {
    this.routeId = this.route.snapshot.paramMap.get("id");
    if (this.routeId) {
      this.getMedicalProviderData();
    } else {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit(): void {}

  resetPassword(email: string) {
    if (email) {
      this.loaderService.shouldRunLoader.next(true);
      this.homeService.requestNewPassword(email).subscribe(
        (response) => {
          this.loaderService.shouldRunLoader.next(false);
        },
        (error) => {
          this.loaderService.shouldRunLoader.next(false);
        }
      );
    }
  }

  editInformation() {
    this.router.navigateByUrl(`edit-provider/${this.medicalProvider.id}`, {
      state: this.medicalProvider,
    });
  }

  getMedicalProvider(id: string) {
    this.loaderService.shouldRunLoader.next(true);
    return this.homeService.getMedicalProviderById(+id).subscribe(
      (response) => {
        this.loaderService.shouldRunLoader.next(false);
        this.medicalProvider = response;
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);
        console.log(error);
      }
    );
  }

  retrieveTypeOfPractice(practiceType: string) {
    if (this.modelsService.practices.value.length) {
      return this.modelsService.practices.value.find(
        (practice) => practice.id == practiceType
      ).text;
    }

    return "";
  }

  getLanguagesSpoken(listOfLanguages: any) {
    if (!listOfLanguages || !listOfLanguages.length) {
      return "-";
    }

    let languagesList = listOfLanguages
      .map((languages: any) => languages.text)
      .join(", ");
    return languagesList;
  }

  getWorkingDaysFor(day: string) {
    day = this.translateService.instant(day);
    let filteredPractice = this.medicalProvider.practice.working_hours.filter(
      (hours: any) => hours.weekday == day
    );
    let workingHours = [];

    if (
      filteredPractice[0] &&
      filteredPractice[0].from_hour &&
      filteredPractice[0].to_hour
    ) {
      let workingHour = `${filteredPractice[0].from_hour} - ${filteredPractice[0].to_hour};`;
      workingHours.push(workingHour.trim());
    }
    if (
      filteredPractice[1] &&
      filteredPractice[1].from_hour &&
      filteredPractice[1].to_hour
    ) {
      let workingHour = `${filteredPractice[1].from_hour} - ${filteredPractice[1].to_hour};`;
      workingHours.push(workingHour.trim());
    }

    if (workingHours.length == 0) {
      workingHours.push(this.translateService.instant("Closed"));
    }

    return workingHours.join("\n").trimLeft();
  }

  deleteProfileHandler() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      autoFocus: false,
      data: { providerId: this.routeId },
    });

    dialogRef.afterClosed().subscribe((result) => {});

    const dialogSubmitSubscription =
      dialogRef.componentInstance.submitClicked.subscribe(
        (result: DialogData) => {
          if (this.routeId) this.deleteProfile(this.routeId);
          dialogSubmitSubscription.unsubscribe();
        }
      );
  }

  deleteProfile(id: string) {
    this.loaderService.shouldRunLoader.next(true);

    this.homeService.deleteMedicalProvider(id).subscribe(
      (response) => {
        this._snackBar.open(
          this.translateService.instant("lbl-provider-deleted"),
          this.translateService.instant("lbl-cancel"),
          {
            panelClass: ["success-snackbar"],
          }
        );
        this.loaderService.shouldRunLoader.next(false);
        this.router.navigate(["list-providers"]);
      },
      (error) => {
        this._snackBar.open(
          this.translateService.instant("lbl-something-wrong"),
          this.translateService.instant("lbl-cancel"),
          {
            panelClass: ["error-snackbar"],
          }
        );

        this.loaderService.shouldRunLoader.next(false);
      }
    );
  }

  getMedicalProviderData() {
    let requests = [];
    if (this.modelsService.languages.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersLanguages());
    }

    if (this.modelsService.practices.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersPractices());
    }

    if (this.modelsService.specialities.value.length == 0) {
      requests.push(this.homeService.getMedicalProvidersSpecialities());
    }

    if (requests.length != 0) {
      forkJoin(requests).subscribe((responses) => {
        this.modelsService.languages.next(responses[0]);
        this.modelsService.practices.next(responses[1]);
        this.modelsService.specialities.next(responses[2]);
        if (this.routeId) {
          this.getMedicalProvider(this.routeId);
        }
      });
    } else {
      if (this.routeId) {
        this.getMedicalProvider(this.routeId);
      }
    }
  }

  getSpecialities(provider: Provider) {
    if (
      !provider ||
      !provider.practice ||
      !provider.practice.medical_speciality ||
      !provider.practice.medical_speciality.length
    ) {
      return "-";
    }

    let specialities = provider.practice.medical_speciality
      .map((languages: any) => this.translateService.instant(languages.text))
      .join(", ");
    return specialities;
  }
}
