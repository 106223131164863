import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { LoginComponent } from "./login.component";

const routes: Routes = [
  {
    path: "signin",
    component: LoginComponent,
    data: { title: marker("Sign In") },
  },
  {
    path: "reset-password",
    component: ForgotPasswordComponent,
    data: { title: marker("Reset Your Password") },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}
