import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "@env/environment";
import { CredentialsService } from "@app/auth";
import { I18nService } from "@app/i18n";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  appLanguage: string = "";
  constructor(
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private translateService: TranslateService
  ) {
    this.appLanguage = this.i18nService.language;

    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.credentialsService.credentials;
    const isApiUrl = request.url.startsWith(environment.serverUrl);
    if (isApiUrl && user) { 
      let acceptLang = "";
      if(this.appLanguage == "en-US") {
        acceptLang = "en";
      } else if(this.appLanguage == 'fr-FR') {
        acceptLang = "fr";
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.access}`,
          "accept-language": acceptLang,
        },
      });
    }

    return next.handle(request);
  }
}
