import { NgModule } from "@angular/core";
import { CommonModule, DatePipe, HashLocationStrategy, LocationStrategy } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FlexLayoutModule } from "@angular/flex-layout";

import { SharedModule } from "@shared";
import { MaterialModule } from "@app/material.module";
import { HomeRoutingModule } from "./home-routing.module";
import { HomeComponent } from "./home.component";
import { ListProvidersComponent } from "./list-providers/list-providers.component";
import { AddNewProviderComponent } from "./add-new-provider/add-new-provider.component";
import { ListUsersComponent } from "./list-users/list-users.component";
import { AddNewAdministratorComponent } from "./add-new-administrator/add-new-administrator.component";
import { AdministratorComponent } from "./administrator/administrator.component";
import { UserComponent } from "./user/user.component";
import { TermsOfUseComponent } from "./terms-of-use/terms-of-use.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ReactiveFormsModule } from "@angular/forms";
import { AvatarModule } from "ngx-avatar";
import { ListAdministratorsComponent } from "./list-administrators/list-administrators.component";
import { AboutComponent } from "./about/about.component";
import { QuillModule } from "ngx-quill";
import { PlatformUserComponent } from "./platform-user/platform-user.component";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { AgmCoreModule } from "@agm/core";
import { environment } from "@env/environment";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    HomeRoutingModule,
    AvatarModule,
    NgxPaginationModule,
    NgxUiLoaderModule,
    MatGoogleMapsAutocompleteModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ['places']
    }),
    QuillModule.forRoot(),
  ],
  declarations: [
    HomeComponent,
    ListProvidersComponent,
    AddNewProviderComponent,
    ListUsersComponent,
    ListAdministratorsComponent,
    AddNewAdministratorComponent,
    AdministratorComponent,
    UserComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    AboutComponent,
    PlatformUserComponent,
  ],
  providers: [
    DatePipe,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5500 } },
  ],
})
export class HomeModule {}
