<div
  class="homepage-container"
  fxLayout="column-reverse"
  fxLayout.gt-md="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.gt-md="center start"
>
  <div class="container-wrappers" fxFlex="87">
    <div class="container main-menu-element-container">
      <div
        class="doctor-header"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div class="doctor-avatar-wrapper">
          <ngx-avatar
            size="100"
            value="100%"
            [name]="platformUser?.first_name + ' ' + platformUser?.last_name"
          ></ngx-avatar>
        </div>
        <h2 id="title">
          {{ platformUser?.first_name + " " + platformUser?.last_name }}
        </h2>
        <p class="subtitle" translate>lbl-platform-user</p>
      </div>
      <h4 translate><span class="orange"></span>lbl-basic-information</h4>
      <div class="basic-info">
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-username</p>
          <h3>{{ platformUser?.username }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-phone-number</p>
          <h3>{{ platformUser?.tel_number }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-address</p>
          <h3>{{ platformUser?.address || "-" }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-email-address</p>
          <h3>{{ platformUser?.email }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-contract-id</p>
          <h3>{{ platformUser?.contract_id }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-birth-date</p>
          <h3>{{ platformUser?.birth_date }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-beneficiary</p>
          <h3
                  style="margin-bottom: 10px"
                  *ngFor="let beneficiary of getBeneficiaries(platformUser)"
          >
            {{ beneficiary.first_name + " " + beneficiary.last_name + " - " + beneficiary.qualification }}
          </h3>
          <h3 *ngIf="getBeneficiaries(platformUser).length < 1">-</h3>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    fxFlex="13"
    fxFlex.md="10"
    [hasResetPassword]="true"
    (resetPassword)="resetPassword(platformUser?.email)"
  ></app-sidebar-buttons>
</div>
