import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { Credentials, CredentialsService } from "./credentials.service";
import jwt_decode from "jwt-decode";
import { ModelsService } from "@app/@shared/services/models.service";
import { Logger } from "@app/@shared/logger.service";

const log = new Logger("AuthenticationService");

export interface LoginContext {
  username: string;
  password: string;
}

export interface User {
  username?: string;
  access?: string;
  refresh?: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  headers = new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "*/*",
  });

  private userSubject: BehaviorSubject<User | null>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private credentialsService: CredentialsService,
    private modelsService: ModelsService
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);

    let userCredentials = this.credentialsService.credentials;

    let userFromCredentials = null;

    if (userCredentials) {
      userFromCredentials = {
        username: userCredentials.username,
        access: userCredentials.access,
        refresh: userCredentials.refresh,
      } as User;

      this.userSubject.next(userFromCredentials);
      this.startRefreshTokenTimer();
    }
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<any> {
    const data = {
      username: context.username,
      password: context.password,
    };

    return this.http
      .post(`${environment.serverUrl}/api/v1/account/token/`, data, {
        headers: this.headers,
      })
      .pipe(
        map((userResponse: any) => {
          let user = {
            access: userResponse.access,
            refresh: userResponse.refresh,
          } as Credentials;

          log.debug("userSubject", user);
          this.credentialsService.setCredentials(user, true);
          this.userSubject.next(user);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  /**
   * Sends email to reset password.
   * @param email users/administrators email.
   */
  sendEmailToResetPassword(email: string) {
    return this.http
      .post(
        `${environment.serverUrl}/api/v1/account/password/request-new/`,
        { email: email },
        {
          headers: this.headers,
        }
      )
      .pipe(
        map(
          (response: any) => {
            if (response && response.success) {
              log.debug("Email Sent");
            }
            return response;
          },
          (error: Error) => {
            log.error("sendEmailToResetPassword error", error);
            return error;
          }
        )
      );
  }

  /**
   * Reset Password
   * @param data username/password/token/uidb
   */
  resetPassword(data: any) {
    return this.http
      .put(`${environment.serverUrl}/api/v1/account/password/reset/`, data, {
        headers: this.headers,
      })
      .pipe(
        map(
          (response: any) => {
            if (response && response.success) {
              log.debug("Password reset succesfully");
            }
          },
          (error: Error) => {
            log.error("resetPassword error", error);
          }
        )
      );
  }

  /**
   * Gets the current user value.
   * @return The current user.
   */
  public get userValue(): any {
    return this.userSubject.value;
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.stopRefreshTokenTimer();
    this.userSubject.next(null!);
    this.credentialsService.setCredentials();
    this.router.navigate(["/signin"], { replaceUrl: true });
    return of(true);
  }

  private refreshTokenTimeout: any;

  /**
   * Refreshes user token
   */
  refreshToken(): any {
    return this.http
      .post<any>(
        `${environment.serverUrl}/api/v1/account/token/refresh/`,
        {
          refresh: (this.userValue && this.userValue.refresh) || "",
        },
        { headers: this.headers }
      )
      .pipe(
        map((user) => {
          log.debug("refreshToken ", user);
          this.userSubject.next(user);
          this.credentialsService.setCredentials(user, true);
          this.startRefreshTokenTimer();
          return user;
        })
      );
  }

  private startRefreshTokenTimer(): any {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.access.split(".")[1]));

    let tokenInfo = this.getDecodedAccessToken(this.userValue.access); // decode token
    this.modelsService.currUserId.next(tokenInfo.user_id);

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);  
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken().subscribe(
        (response: any) => {},
        (error: Error) => {
          //log.debug("Error", error);
          this.logout();
        }
      );
    }, timeout);
  }

  private getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
