<div class="homepage-container">
  <div class="container-wrappers" fxFlex="85">
    <div class="container main-menu-element-container">
      <div
        class="doctor-header"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <div class="doctor-avatar-wrapper">
          <ngx-avatar
            size="100"
            value="100%"
            [name]="
              currAdministrator?.first_name + ' ' + currAdministrator?.last_name
            "
            initialsSize="4"
          ></ngx-avatar>
        </div>
        <h2 id="title">
          {{
            currAdministrator?.first_name + " " + currAdministrator?.last_name
          }}
        </h2>
        <p
          *ngIf="
            currAdministrator?.is_admin && !currAdministrator?.is_superuser
          "
          class="subtitle"
          translate
        >
          lbl-administrator
        </p>
        <p
          *ngIf="currAdministrator?.is_admin && currAdministrator?.is_superuser"
          class="subtitle"
          translate
        >
          lbl-super-administrator
        </p>
      </div>
      <h4 translate><span class="orange"></span>lbl-basic-information</h4>
      <div class="basic-info">
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-username</p>
          <h3>{{ currAdministrator?.username }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-phone-number</p>
          <h3>{{ currAdministrator?.tel_number }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-address</p>
          <h3>{{ currAdministrator?.address }}</h3>
        </div>
        <div class="bubble" fxFlex="48%">
          <p translate>lbl-email</p>
          <h3>{{ currAdministrator?.email }}</h3>
        </div>
      </div>
    </div>
  </div>
  <app-sidebar-buttons
    [hasEditInformation]="isLoggedInAdminSuperUser"
    [hasResetPassword]="true"
    [hasSuspendProfile]="
      currAdministrator?.is_active &&
      isLoggedInAdminSuperUser &&
      !isAdministratorOnOwnScreen()
    "
    [hasEnableProfile]="
      !currAdministrator?.is_active &&
      isLoggedInAdminSuperUser &&
      !isAdministratorOnOwnScreen()
    "
    (editInformation)="editInformation()"
    (resetPassword)="resetPassword(currAdministrator?.email)"
    (suspendProfile)="suspendProfile(currAdministrator?.id, false)"
    (enableProfile)="suspendProfile(currAdministrator?.id, true)"
    fxFlex="15"
    fxFlex.md="10"
  ></app-sidebar-buttons>
</div>
