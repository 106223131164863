import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { Administrator } from "@app/@shared/interfaces/administrator.interface";
import { LoaderService } from "@app/@shared/services/loader.service";
import { Observable } from "rxjs";
import { HomeService } from "./home.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  myControl = new FormControl();
  filteredOptions!: Observable<string[]>;

  data = {
    limit: 5,
    offset: 1,
  };

  users: any;
  adminResponse: any;

  providersUsers: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  providersAdmin: MatTableDataSource<Administrator> =
    new MatTableDataSource<Administrator>([]);

  displayedColumnsAdmin: string[] = [
    "icon",
    "name",
    "username",
    "address",
    "tel_number",
  ];
  displayedColumnsUsers: string[] = [
    "icon",
    "name",
    "username",
    "address",
    "tel_number",
  ];

  constructor(
    private router: Router,
    private homeService: HomeService,
    private loaderService: LoaderService
  ) {
    const _tempCalls = [];
    this.loaderService.shouldRunLoader.next(true);
    _tempCalls.push(this.getAllUsers());
    _tempCalls.push(this.getAllAdmins());
  }

  ngOnInit() {}

  goToIndividualScreen(entity: any, type: string) {
    console.log(entity, type);
    if (type == "platform-user") {
      this.router.navigate(["/platform-user", entity.id]);
    } else {
      this.router.navigate(["/administrator", entity.id]);
    }
  }

  getAllUsers() {
    return this.homeService.getAllUsers(this.data).subscribe(
      (response) => {
        this.loaderService.shouldRunLoader.next(false);

        this.users = response;
        this.providersUsers = new MatTableDataSource(this.users.results);
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);
      }
    );
  }

  getAllAdmins() {
    return this.homeService.getAllAdmins(this.data).subscribe(
      (response: Administrator[]) => {
        this.loaderService.shouldRunLoader.next(false);

        this.adminResponse = response;
        this.providersAdmin = new MatTableDataSource(
          this.adminResponse.results
        );
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);
      }
    );
  }

  mouseEnter(event: any) {
    let element = event.srcElement;
    element.setAttribute("title", element.innerHTML);
  }

  mouseLeave(event: any) {}
}
