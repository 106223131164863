<a
  mat-list-item
  [ngStyle]="{ 'padding-left': depth * 12 + 'px' }"
  (click)="onItemSelected(item)"
  [ngClass]="{
    active: item.route
      ? router.isActive(item.route, true)
      : checkForChildrenRoutes(item),
    expanded: item.expanded,
    child: depth > 0
  }"
  class="menu-list-item"
>
  <img
    *ngIf="item.iconName"
    class="sidenav-icon"
    src="{{ '../../../assets/' + item.iconName }}"
  />
  <span class="sidebar-nav-name">{{ item.displayName | translate }}</span>
  <span fxFlex *ngIf="item.children && item.children.length">
    <span fxFlex></span>
    <mat-icon [@indicatorRotate]="item.expanded ? 'expanded' : 'collapsed'">
      expand_more
    </mat-icon>
  </span>
</a>
<div *ngIf="item.expanded">
  <app-menu-list-item
    *ngFor="let child of item.children"
    [item]="child"
    [depth]="depth + 1"
  >
  </app-menu-list-item>
</div>
