import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";

import { Logger } from "@shared";
import { AuthenticationService } from "./authentication.service";
import { CredentialsService } from "./credentials.service";

const log = new Logger("AuthenticationGuard");

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.credentialsService.isAuthenticated();
    if (user) {
      // logged in so return true
      return true;
    } else {
      if (window.location.href.indexOf("/reset-password") > -1) {
        let index = window.location.href.indexOf("/reset-password");
        let urlToNavigate = window.location.href.substring(index);
        this.router.navigateByUrl(urlToNavigate);
        return false;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(["/signin"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
