import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "@app/@shared/services/loader.service";
import { I18nService } from "@app/i18n";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "../home.service";

export interface TermsOfUseResponse {
  created_at: Date;
  id: number;
  text?: string;
  text_fr?: string;
  text_en?: string;
}

export interface TermsOfUseRequest {
  text_fr?: string;
  text_en?: string;
}

@Component({
  selector: "app-terms-of-use",
  templateUrl: "./terms-of-use.component.html",
  styleUrls: ["./terms-of-use.component.scss"],
})
export class TermsOfUseComponent implements OnInit {
  editorOptions = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["link", "image"],
      [{ align: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: 1 }, { header: 2 }], // custom button values
    ],
  };

  frenchTermsOfUseForm!: FormGroup;
  englishTermsOfUseForm!: FormGroup;
  termsOfUse?: TermsOfUseResponse;
  language: string = "en-US";

  view: boolean = true;
  edit: boolean = false;

  appLanguage: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private homeService: HomeService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {
    this.appLanguage = this.i18nService.language;
    this.language = this.appLanguage;
  }

  async ngOnInit() {
    await this.getTermsOfUse();
    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });
  }

  getTermsOfUse() {
    this.loaderService.shouldRunLoader.next(true);
    this.homeService.getTermsOfUse().subscribe(
      (response) => {
        this.removeExtraNewline(response);
        this.termsOfUse = response;
        this.loaderService.shouldRunLoader.next(false);
      },
      (error) => {
        console.log(error);
        this.loaderService.shouldRunLoader.next(false);
      }
    );
  }

  /**
   * Create Form Function
   */
  createForm() {
    this.englishTermsOfUseForm = this.formBuilder.group({
      englishTermsOfUse: [this.termsOfUse?.text_en || "", Validators.required],
    });

    this.frenchTermsOfUseForm = this.formBuilder.group({
      frenchTermsOfUse: [this.termsOfUse?.text_fr || "", Validators.required],
    });
  }

  editTermsOfUse() {
    this.createForm();
    this.view = false;
    this.edit = true;
  }

  checkCheckBoxvalue(event: any) {
    if (event.target.checked) {
      this.language = "fr-FR";
      this.view = true;
      this.edit = false;
    } else {
      this.language = "en-US";
      this.view = true;
      this.edit = false;
    }
  }

  goBack() {
    this.view = true;
    this.edit = false;
  }

  removeExtraNewline(response: any) {
    let spaceSign = /(<p><br><\/p>)+/gi;
    response.text_fr = response.text_fr.replace(spaceSign, "<p><br></p>");
    response.text_en = response.text_en.replace(spaceSign, "<p><br></p>");
  }

  saveTermsOfUse() {
    let data: TermsOfUseRequest = {
      text_fr: this.frenchTermsOfUseForm.value.frenchTermsOfUse,
      text_en: this.englishTermsOfUseForm.value.englishTermsOfUse,
    };

    this.loaderService.shouldRunLoader.next(true);
    this.homeService.saveTermsOfUse(data).subscribe(
      (response) => {
        this.loaderService.shouldRunLoader.next(false);
        this.getTermsOfUse();
        this.goBack();
      },
      (error) => {
        this.loaderService.shouldRunLoader.next(false);
        console.log(error);
      }
    );
  }
}
