<div *ngIf="!form">
  <label class="switch" for="{{ checkboxName }}">
    <input
      type="checkbox"
      id="{{ checkboxName }}"
      (change)="checkCheckBoxvalue($event)"
    />
    <div class="slider round"></div>
    <span class="no">No</span>
    <span class="yes">Yes</span>
  </label>
</div>

<div *ngIf="form" [formGroup]="form">
  <label class="switch" for="{{ checkboxName }}">
    <input
      type="checkbox"
      id="{{ checkboxName }}"
      (change)="checkCheckBoxvalue($event)"
      formControlName="{{ nameOfControl }}"
    />
    <div class="slider round"></div>
    <span class="no">No</span>
    <span class="yes">Yes</span>
  </label>
</div>
