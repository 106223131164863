<div>
  <button
    mat-icon-button
    [disableRipple]="true"
    [matMenuTriggerFor]="languageMenu"
  >
    <div
      class="language-selector-header"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="element flag" [ngClass]="getLanguageIcon(currentLanguage)">
        <p></p>
      </div>
      <p class="element country-text">
        {{ getAbbr(currentLanguage) | translate }}
      </p>
      <mat-icon class="unfold"> unfold_more </mat-icon>
    </div>
  </button>
  <mat-menu #languageMenu="matMenu" yPosition="below">
    <div
      *ngFor="let language of languages"
      (click)="setLanguage(language)"
      translate
      class="language-selector-dropdown"
      fxLayout="row"
    >
      <div class="element flag" [ngClass]="getLanguageIcon(language)">
        <p></p>
      </div>
      <p class="element country-text">{{ language | translate }}</p>
    </div>
  </mat-menu>
</div>
