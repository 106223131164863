<mat-toolbar
  class="navbar"
  color="white"
  fxLayoutAlign="center center"
  fxLayout="column"
>
  <div
    class="subheader"
    fxLayout="column"
    fxLayout.gt-sm="row"
    fxFlex="95"
    fxLayoutAlign="space-between center"
  >
    <div class="logo-container" fxLayout="row">
      <div>
        <button class="menu-button" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <app-logo></app-logo>
    </div>
    <div *ngIf="shouldShowAutocomplete()" class="autocomplete" fxFlex="20">
      <form class="example-form" (ngSubmit)="viewSearch($event)">
        <input
          aria-label="Search Entity"
          [matAutocomplete]="auto"
          #trigger="matAutocompleteTrigger"
          [placeholder]="getPlaceholder() | translate"
          (keydown)="viewSearch($event)"
          [formControl]="searchEntityCtrl"
        />
        <mat-icon matSuffix [ngStyle]="{ color: '#8C99A7' }">search</mat-icon>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let searchElement of filteredSearchData"
            [value]="searchElement.name"
            (click)="goToProviderProfile(searchElement)"
          >
            <p
              *ngIf="
                searchElement.practice &&
                searchElement &&
                searchElement?.type_of_practice &&
                searchElement?.type_of_practice.text == 'Clinic'
              "
              id="search-name"
            >
              {{ searchElement.clinic_name }}
            </p>
            <p
              *ngIf="
                searchElement.practice &&
                searchElement &&
                searchElement?.type_of_practice &&
                searchElement?.type_of_practice.text != 'Clinic'
              "
              id="search-name"
            >
              {{ searchElement.name }}
            </p>
            <p *ngIf="!searchElement.practice" id="search-name">
              {{ searchElement.name }}
            </p>

            <span *ngIf="searchElement.practice" id="search-practice">{{
              searchElement.practice.location.address || ""
            }}</span>
          </mat-option>
          <mat-option>
            <p id="view-all-results" (click)="viewAll($event)">
              {{ "lbl-view-all" | translate }}
              {{ count > 0 ? "(" + count + ")" : "" }}
            </p>
          </mat-option>
        </mat-autocomplete>
      </form>
    </div>
    <app-navbar-details
      [currAdministrator]="currAdministrator"
    ></app-navbar-details>
  </div>
  <mat-divider></mat-divider>
</mat-toolbar>
