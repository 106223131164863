import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Administrator } from "../interfaces/administrator.interface";

@Injectable({
  providedIn: "root",
})
export class ModelsService {
  listOfMedicalProviders = new BehaviorSubject<any[]>([]);
  medicalProvidersSearch = new BehaviorSubject<string>("");
  searchData = new BehaviorSubject<string>("");

  languages = new BehaviorSubject<any[]>([]);
  practices = new BehaviorSubject<any[]>([]);
  specialities = new BehaviorSubject<any[]>([]);

  currUserId = new BehaviorSubject<number>(0);
  currLoggedInAdminSuperAdmin = new BehaviorSubject<boolean | undefined>(undefined);
  constructor() {}
}
