<div
  fxFill
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="center center"
  class="mat-typography"
>
  <div
    fxFill
    fxLayout="column"
    fxFlex="30%"
    fxFlex.xs="100%"
    class="logo-wrapper"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      fxLayoutGap="2rem"
      class="title-and-language"
    >
      <app-logo size="large"></app-logo>
    </div>

    <div
      class="login-container-wrapper"
      fxLayout="row"
      fxLayoutAlign="center center"
    >
      <div class="login-container" fxLayoutAlign="center center">
        <form
          (ngSubmit)="login()"
          fxFlex="90%"
          [formGroup]="loginForm"
          novalidate
        >
          <div class="login-box">
            <h2 class="login" translate>lbl-login</h2>
            <p translate>
              lbl-enter-username-password
            </p>
            <div class="login-error" [hidden]="!error || isLoading" translate>
              lbl-username-incorrect
            </div>
            <br />
            <div class="login-fields" fxLayout="column">
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  type="text"
                  matInput
                  formControlName="username"
                  autocomplete="username"
                  [placeholder]="'lbl-enter-username' | translate"
                  required
                />
                <mat-error
                  *ngIf="
                    loginForm.controls.username.invalid &&
                    loginForm.controls.username.touched
                  "
                >
                  <span translate>lbl-username-required</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field [hideRequiredMarker]="true">
                <input
                  type="password"
                  matInput
                  formControlName="password"
                  autocomplete="current-password"
                  [placeholder]="'lbl-enter-password' | translate"
                  [type]="hide ? 'password' : 'text'"
                  required
                />
                <mat-icon
                  class="mat-icon-visibility"
                  matSuffix
                  (mouseenter)="hide = !hide"
                  (mouseleave)="hide = !hide"
                  >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
                >
                <mat-error
                  *ngIf="
                    loginForm.controls.password.invalid &&
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors!.required
                  "
                >
                  <span translate>lbl-password-required</span>
                </mat-error>
                <mat-error
                  *ngIf="
                    loginForm.controls.password.invalid &&
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors!.minlength
                  "
                >
                  <span translate
                    >error-password-character</span
                  >
                </mat-error>
                <mat-error
                  *ngIf="
                    loginForm.controls.password.invalid &&
                    loginForm.controls.password.touched &&
                    loginForm.controls.password.errors!.pattern
                  "
                >
                  <span translate
                    >error-password-correct-form</span
                  >
                </mat-error>
              </mat-form-field>
              <br />
              <a
                class="auth-navigation"
                translate
                (click)="openForgotPasswordModal()"
                >lbl-forgot-password</a
              >
            </div>
          </div>
          <div class="auth-button-border" fxLayoutAlign="center center">
            <button
              mat-button
              color="primary"
              id="auth-button"
              class="login-page-button"
              type="submit"
              [disabled]="isLoading"
            >
              <app-loader
                class="inline-loader"
                [isLoading]="isLoading"
              ></app-loader>
              <span *ngIf="!isLoading" translate>lbl-login</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div fxFlex="70%" fxFlex.xs="100%" class="image-wrapper"></div>
</div>
