import { Title } from "@angular/platform-browser";
import { Component, OnInit, Input, ViewChild, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";

import { CredentialsService } from "@app/auth";
import { debounceTime, distinctUntilChanged, filter } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { ModelsService } from "@app/@shared/services/models.service";
import { HomeService } from "@app/home/home.service";
import { Administrator } from "@app/@shared/interfaces/administrator.interface";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "@app/i18n";
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  getAllMedicalProviders$!: Subscription;
  @Input() sidenav!: MatSidenav;

  searchEntityCtrl = new FormControl("");
  filteredSearchData: any[] = []; // dropdown search data
  search: string = "";
  currAdministrator: Administrator | undefined;
  @ViewChild(MatAutocompleteTrigger) matAutocomplete:
    | MatAutocompleteTrigger
    | undefined;

  count: number = 0;

  data = {
    limit: "5",
    search: "",
  };

  appLanguage: string = "";

  constructor(
    private titleService: Title,
    private router: Router,
    private credentialsService: CredentialsService,
    private modelService: ModelsService,
    private homeService: HomeService,
    private translateService: TranslateService,
    private i18nService: I18nService
  ) {
    this.appLanguage = this.i18nService.language;
    this.getCurrentAdministrator();
    this.translateService.onLangChange.subscribe((lang) => {
      this.appLanguage = lang.lang;
    });
  }

  ngOnInit() {
    this.sidenav.open();
    this.searchEntityCtrl.valueChanges
      .pipe(debounceTime(600), distinctUntilChanged())
      .subscribe((searchInput: any) => {
        this.data.search = searchInput;
        if (this.router.url === "/list-administrators") {
          this.getAllMedicalProviders$! = this.homeService
            .getAllAdmins(this.data)
            .subscribe(
              (response) => {
                this.filteredSearchData = response.results;
                this.count = response.count;
              },
              (error) => {}
            );
        } else if (this.router.url === "/list-providers") {
          this.getAllMedicalProviders$! = this.homeService
            .getAllMedicalProviders(this.data, this.appLanguage)
            .subscribe(
              (response) => {
                this.filteredSearchData = response.results;
                this.count = response.count;
              },
              (error) => {}
            );
        } else if (this.router.url === "/list-users") {
          this.getAllMedicalProviders$! = this.homeService
            .getAllUsers(this.data)
            .subscribe(
              (response) => {
                this.filteredSearchData = response.results;
                this.count = response.count;
              },
              (error) => {}
            );
        }
      });

    this.router.events
      .pipe(filter((r) => r instanceof NavigationEnd))
      .subscribe((r) => {
        this.searchEntityCtrl.reset();
        this.filteredSearchData = [];
        this.modelService.searchData.next("");
        this.count = 0;
        this.getAllMedicalProviders$.unsubscribe();
      });
  }

  get username(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.username : null;
  }

  shouldShowAutocomplete() {
    if (
      this.router.url === "/list-providers" ||
      this.router.url === "/list-administrators" ||
      this.router.url === "/list-users"
    ) {
      return true;
    }

    return false;
  }

  get title(): string {
    return this.titleService.getTitle();
  }

  goToProviderProfile(entity: any) {
    if (this.router.url === "/list-providers") {
      this.router.navigate(["/provider", entity.id]);
    } else if (this.router.url === "/list-administrators") {
      this.router.navigate(["/administrator", entity.id]);
    } else if (this.router.url === "/list-users") {
      this.router.navigate(["/platform-user", entity.id]);
    }

    this.searchEntityCtrl.reset();
  }

  viewAll(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.matAutocomplete?.closePanel();
    if (this.router.url === "/list-providers") {
      this.modelService.searchData.next(this.data.search);
      this.searchEntityCtrl.reset();
    } else if (this.router.url === "/list-administrators") {
      this.modelService.searchData.next(this.data.search);
      this.searchEntityCtrl.reset();
    } else if (this.router.url === "/list-users") {
      this.modelService.searchData.next(this.data.search);
      this.searchEntityCtrl.reset();
    } else {
      return;
    }
  }

  viewSearch(event: any) {
    if (event.keyCode == 13) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  getPlaceholder() {
    if (this.router.url === "/list-providers") {
      return "lbl-find-providers";
    } else if (this.router.url === "/list-administrators") {
      return "lbl-find-administrators";
    } else {
      return "lbl-find-users";
    }
  }

  private getCurrentAdministrator() {
    let currAdministratorId = this.modelService.currUserId.value;
    this.homeService.getAdminById(currAdministratorId).subscribe(
      (response) => {
        this.currAdministrator = response;
        this.modelService.currLoggedInAdminSuperAdmin.next(
          this.currAdministrator!.is_superuser
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
    if (this.getAllMedicalProviders$) {
      this.getAllMedicalProviders$.unsubscribe();
    }
  }
}
