import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";

import { environment } from "@env/environment";
import {
  RouteReusableStrategy,
  ApiPrefixInterceptor,
  ErrorHandlerInterceptor,
  SharedModule,
} from "@shared";
import { AuthenticationService, AuthModule } from "@app/auth";
import { HomeModule } from "./home/home.module";
import { ShellModule } from "./shell/shell.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { appInitializer } from "./@shared/helpers/app.initializer";
import { JwtInterceptor } from "./@shared/helpers/jwt.interceptor";
import { ErrorInterceptor } from "./@shared/helpers/error.interceptor";
import { NgxPaginationModule } from "ngx-pagination";
import { AgmCoreModule } from '@agm/core';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";

@NgModule({
  imports: [
    NgxPaginationModule,
    BrowserModule,
    ServiceWorkerModule.register("./ngsw-worker.js", {
      enabled: environment.production,
    }),
    FormsModule,
    HttpClientModule,
    RouterModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    ShellModule,
    HomeModule,
    AuthModule,
    NgxUiLoaderModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiPrefixInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthenticationService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
