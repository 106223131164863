import { AuthenticationService } from "@app/auth/authentication.service";

/**
 * Runs before the app starts up, and tries to automatically authenticate the user, if the user
 * didn't logout and the browser still contains a valid refresh token.
 */
export function appInitializer(authenticationService: AuthenticationService) {
  return () =>
    new Promise((resolve) => {
      // attempt to refresh token on app start up to auto authenticate
      authenticationService.refreshToken().subscribe().add(resolve);
    });
}
